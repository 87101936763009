import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledTitle } from './styles';
export const DividerTitle = ({ title }) => {
  return (
    <Grid item xs={12}>
      <StyledTitle>
        <h2>{title}</h2>
      </StyledTitle>
    </Grid>
  );
};
