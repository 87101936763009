import React, { useState, useCallback, Fragment, useEffect, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography, FormControlLabel, TextField, Fab } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyMultiSelect } from '../../../components/MyMultiSelect';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

import { UserContext } from '../../../contexts/UserContext';

export const ProductionRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { user } = useContext(UserContext);
  // Controladores para los campos de formulario
  const descripcion = useInputValue('');
  const codigoProducto = useInputValue('');
  const numeroOrdenProduccion = useInputValue('');
  const cantidadImpresiones = useInputValue('');
  const prioridad = useInputValue('');
  const modeloProduccion = useInputValue('');
  const observaciones = useInputValue('');
  const [parte, setParte] = useState([]);
  const [tira, setTira] = useState([]);
  const [retira, setRetira] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [mostrar, setMostrar] = useState(false);
  const [listaPreparacion, setListaPreparacion] = useState([]);
  const [listaPreparacionOption, setListaPreparacionOption] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  // Funciones y Callbacks
  const setDataF = async trabajo => {
      descripcion.setValue(trabajo.descripcion);
      codigoProducto.setValue(trabajo.codigo);
      numeroOrdenProduccion.setValue(trabajo.numero_orden_produccion);
      cantidadImpresiones.setValue(trabajo.cantidad_impresiones || '');
      prioridad.setValue(trabajo.prioridad || '');
      trabajo.lados === '2' && setMostrar(true);
    },
    setData = useCallback(setDataF, []);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      if (id) {
        const [
          trabajo,
          parteResponse,
          listaResponse,
          parteListaResponse,
          tiraResponse,
          retiraResponse,
          procesosResponse,
          _dataTable,
        ] = await Promise.all([
          getData({ source: `/jobs/${id}/edit` }),
          getData({ source: `/production/${id}/parte-diario`, bg: true }),
          getData({ source: `/production/${id}/lista-preparacion`, bg: true }),
          getData({ source: `/production/${id}/parte-lista`, bg: true }),
          getData({ source: `/production/${id}/tira`, bg: true }),
          getData({ source: `/production/${id}/retira`, bg: true }),
          getData({ source: `/production/${id}/procesos`, bg: true }),
          getData({ source: `/production/${id}/data-table`, bg: true }),
        ]);
        trabajo.status === 200 && setData(trabajo.data);
        listaResponse.status === 200 && setListaPreparacionOption(listaResponse.data);
        parteListaResponse.status === 200 && setListaPreparacion(parteListaResponse.data);
        _dataTable.status === 200 && setDataTable(_dataTable.data);
        if (parteResponse.status === 200) {
          if (parteResponse.data.length) {
            setParte(parteResponse.data);
            modeloProduccion.setValue(parteResponse.data[0].modelo_produccion);
            observaciones.setValue(parteResponse.data[0].observaciones);
          } else {
            setParte([
              {
                numero_orden_produccion: trabajo.data.numero_orden_produccion,
                operario: user.value && user.value.id,
                name: user.value && user.value.name,
                id: null,
                fecha: '',
                hora_inicio: '',
                hora_fin: '',
                cantidad: '',
              },
            ]);
          }
        }

        tiraResponse.status === 200 && setTira(tiraResponse.data);
        retiraResponse.status === 200 && trabajo.data.lados === '2' && setRetira(retiraResponse.data);
        procesosResponse.status === 200 && setProcesos(procesosResponse.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getData, setData, user.value]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    if (!prioridad.value || !modeloProduccion.value || !observaciones.value || !listaPreparacion.length) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      prioridad: prioridad.value,

      parte: parte,
      modeloProduccion: modeloProduccion.value,
      observaciones: observaciones.value,
      listaPreparacion: listaPreparacion,

      tira: tira,
      retira: retira,
      procesos: procesos,
    };
    const response = await getData({ source: `/production/${id}`, body: body, json: true, method: 'PUT' });
    if (response.status === 200) {
      await swal('Informacion actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <DataTable title='Trabajo' data={dataTable} />
          </Grid>
          <MyInput name='descripcion' label='Descripcion' controller={descripcion} disabled={true} />
          <MyInput name='codigoProducto' label='Codigo de Producto' controller={codigoProducto} disabled={true} />
          <MyInput name='numeroOrdenProduccion' label='#Order de Produccion' controller={numeroOrdenProduccion} disabled={true} />
          <MyInput name='cantidadImpresiones' label='Cantidad de Pliegos Maquina' controller={cantidadImpresiones} disabled={true} />
          <MyInput name='prioridad' label='Prioridad' required={true} controller={prioridad} disabled={loading} type='number' />
          <Grid item xs={12}>
            <ParteDataTable title='Preparación' data={parte} setData={setParte} />
          </Grid>
          <MySelect
            name='modeloProduccion'
            label='Modelo de Produccion'
            required={true}
            controller={modeloProduccion}
            disabled={loading}
            data={[
              { id: 1, name: 'MODELO 1' },
              { id: 2, name: 'MODELO 2' },
              { id: 3, name: 'MODELO 3' },
            ]}
          />
          <MyInput
            name='observaciones'
            label='Observaciones'
            toCase='upper'
            controller={observaciones}
            rows={4}
            md={12}
            disabled={loading}
          />
          <MyMultiSelect
            data={listaPreparacion}
            setData={setListaPreparacion}
            label='Lista de Preparacion'
            options={listaPreparacionOption}
          />
          <Grid item xs={12}>
            <TiraDataTable title='Tira' data={tira} setData={setTira} />
          </Grid>
          {mostrar && (
            <Grid item xs={12}>
              <RetiraDataTable title='Retira' data={retira} setData={setRetira} />
            </Grid>
          )}
          <Grid item xs={12}>
            <ProcesosDataTable title='Procesos de Acabado' data={procesos} setData={setProcesos} />
          </Grid>
          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const ParteDataTable = ({ title, data, setData }) => {
  const columns = [
    {
      name: 'numero_orden_produccion',
      label: '#Orden Produccion',
      options: {
        filter: true,
      },
    },
    {
      name: 'name',
      label: 'Operario',
      options: {
        filter: true,
      },
    },
    {
      name: '',
      label: 'Fijar Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='primary'
              aria-label='Inicio'
              onClick={() => {
                const tmp = [...data];
                tmp[index].fecha = moment().format('DD/MM/YYYY');
                tmp[index].hora_inicio = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'fecha',
      label: 'Fecha',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />;
        },
      },
    },
    {
      name: 'hora_inicio',
      label: 'Hora de Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },

    {
      name: '',
      label: 'Fijar Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='secondary'
              aria-label='Fin'
              onClick={() => {
                const tmp = [...data];
                tmp[index].hora_fin = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'hora_fin',
      label: 'Hora de Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'stacked',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};

const TiraDataTable = ({ title, data, setData }) => {
  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const columns = [
    {
      name: '',
      label: 'Fijar Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='primary'
              aria-label='Inicio'
              onClick={() => {
                const tmp = [...data];
                tmp[index].fecha = moment().format('DD/MM/YYYY');
                tmp[index].hora_inicio = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'fecha',
      label: 'Fecha',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />;
        },
      },
    },
    {
      name: 'hora_inicio',
      label: 'Hora de Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },

    {
      name: '',
      label: 'Fijar Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='secondary'
              aria-label='Fin'
              onClick={() => {
                const tmp = [...data];
                tmp[index].hora_fin = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'hora_fin',
      label: 'Hora de Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },
    {
      name: 'cantidad',
      label: 'Avance',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField type='number' value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'cantidad', event.target.value);
            }}
          />
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'stacked',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};

const RetiraDataTable = ({ title, data, setData }) => {
  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const columns = [
    {
      name: '',
      label: 'Fijar Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='primary'
              aria-label='Inicio'
              onClick={() => {
                const tmp = [...data];
                tmp[index].fecha = moment().format('DD/MM/YYYY');
                tmp[index].hora_inicio = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'fecha',
      label: 'Fecha',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />;
        },
      },
    },
    {
      name: 'hora_inicio',
      label: 'Hora de Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },

    {
      name: '',
      label: 'Fijar Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='secondary'
              aria-label='Fin'
              onClick={() => {
                const tmp = [...data];
                tmp[index].hora_fin = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'hora_fin',
      label: 'Hora de Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },
    {
      name: 'cantidad',
      label: 'Avance',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField type='number' value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'cantidad', event.target.value);
            }}
          />
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'stacked',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};

const ProcesosDataTable = ({ title, data, setData }) => {
  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const columns = [
    {
      name: 'proceso',
      label: 'Proceso',
      options: {
        filter: true,
      },
    },
    {
      name: '',
      label: 'Fijar Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='primary'
              aria-label='Inicio'
              onClick={() => {
                const tmp = [...data];
                tmp[index].fecha = moment().format('DD/MM/YYYY');
                tmp[index].hora_inicio = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'fecha',
      label: 'Fecha',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />;
        },
      },
    },
    {
      name: 'hora_inicio',
      label: 'Hora de Inicio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },

    {
      name: '',
      label: 'Fijar Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <Fab
              color='secondary'
              aria-label='Fin'
              onClick={() => {
                const tmp = [...data];
                tmp[index].fecha_fin = moment().format('DD/MM/YYYY');
                tmp[index].hora_fin = moment().format('HH:mm:ss');
                setData(tmp);
              }}
            >
              <AccessTimeIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'hora_fin',
      label: 'Hora de Fin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel control={<TextField value={value || ''} disabled={true} />} />
        ),
      },
    },
    {
      name: 'cantidad',
      label: 'Avance',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField type='number' value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'cantidad', event.target.value);
            }}
          />
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'stacked',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};

const DataTable = ({ title, data }) => {
  const columns = [
    {
      name: 'material',
      label: 'Material',
    },
    {
      name: 'gramaje',
      label: 'Gramaje',
    },
    {
      name: 'ancho_maquina',
      label: 'Ancho Maquina',
    },
    {
      name: 'largo_maquina',
      label: 'Largo Maquina',
    },
    {
      name: 'cantidad_procesos',
      label: 'Cantidad de Procesos',
    },
    {
      name: 'merma',
      label: 'Demasia',
    },
    {
      name: 'colores',
      label: 'Secuencia de Colores',
    },
    {
      name: 'pantones',
      label: 'Pantones',
    },
    {
      name: 'observaciones_planeamiento',
      label: 'Observaciones',
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};
