import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import swal from "sweetalert";
import MUIDataTable from "mui-datatables";

import { MyPaper } from "../../../components/MyPaper";
import { MyInput } from "../../../components/MyInput";
import { MySelect } from "../../../components/MySelect";
import { MyMultiSelect } from "../../../components/MyMultiSelect";

import { useInputValue } from "../../../hooks/useInputValue";
import { useFetch } from "../../../hooks/useFetch";

export const QualityControlRegister = ({ id }) => {
  const [title, setTitle] = useState("Registrar");
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const cantidadInspeccionar = useInputValue("");
  const nivelInspeccion = useInputValue("");
  const tamanoMuestra = useInputValue("");
  const condicionCalidad = useInputValue("");
  const [trabajo, setTrabajo] = useState([]);
  const [verificacionCalidad, setVerificacionCalidad] = useState([]);

  // Data para los selects
  const _nivelInspeccion = [
    { name: "NORMAL" },
    { name: "RIGUROSO" },
    { name: "REDUCIDO" },
  ];
  const _verificacionCalidad = [
    { name: "COLOR" },
    { name: "TEXTO" },
    { name: "GRAMAJE" },
    { name: "MEDIDAS" },
    { name: "RECUBRIMIENTOS" },
    { name: "ACABADO" },
    { name: "PRUEBA FUNCIONAL" },
  ];
  const _condicionCalidad = [
    { name: "PENDIENTE" },
    { name: "RETENIDO" },
    { name: "LIBERADO" },
  ];

  // Inicializando
  useEffect(() => {
    if (id) {
      setTitle("Editar");
      (async function () {
        const res = await getData({ source: `/quality-control/${id}` });
        if (res.status === 200) {
          const { job, quality, verificacionCalidad } = res.data;
          setTrabajo(job);
          cantidadInspeccionar.setValue(quality.cantidad_inspeccionar || "");
          nivelInspeccion.setValue(quality.nivel_inspeccion || "");
          tamanoMuestra.setValue(quality.tamano_muestra || "");
          setVerificacionCalidad(verificacionCalidad || []);
          condicionCalidad.setValue(quality.condicion_calidad || "");
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getData]);

  // Manejador de envio del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !cantidadInspeccionar.value ||
      !nivelInspeccion.value ||
      !tamanoMuestra.value ||
      !verificacionCalidad.length ||
      !condicionCalidad.value
    ) {
      swal("Debe llenar los campos requeridos.", { icon: "error" });
      return;
    }

    const body = {
      cantidadInspeccionar: cantidadInspeccionar.value,
      nivelInspeccion: nivelInspeccion.value,
      tamanoMuestra: tamanoMuestra.value,
      verificacionCalidad: verificacionCalidad,
      condicionCalidad: condicionCalidad.value,
    };
    setError(undefined);
    const response = await getData({
      source: `/quality-control/${id}`,
      body: body,
      json: true,
      method: "PUT",
    });
    if (response.status === 200) {
      await swal("Informacion actualizada con exito.", { icon: "success" });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <DataTable title="Trabajo" data={trabajo} />
          </Grid>

          <MyInput
            name="cantidadInspeccionar"
            label="Cantidad a Inspeccionar"
            type="number"
            controller={cantidadInspeccionar}
            required={true}
            disabled={loading}
          />
          <MySelect
            name="nivelInspeccion"
            label="Nivel de Inspeccion"
            controller={nivelInspeccion}
            required={true}
            disabled={loading}
            data={_nivelInspeccion}
          />
          <MyInput
            name="tamanoMuestra"
            label="Tamaño de la Muestra"
            type="number"
            controller={tamanoMuestra}
            required={true}
            disabled={loading}
          />
          <MyMultiSelect
            data={verificacionCalidad}
            setData={setVerificacionCalidad}
            label="Verificacion de Calidad"
            options={_verificacionCalidad}
          />
          <MySelect
            name="condicionCalidad"
            label="Condicion de Calidad"
            controller={condicionCalidad}
            required={true}
            disabled={loading}
            data={_condicionCalidad}
          />

          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid
              container
              item
              justify="flex-start"
              alignItems="center"
              xs={12}
              md={6}
            >
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant="h6" color="textSecondary">
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant="h6" color="error">
                  {error}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data }) => {
  const columns = [
    {
      name: "numero_orden_produccion",
      label: "#Orden de Produccion",
    },
    {
      name: "tipo_trabajo",
      label: "Tipo de Trabajo",
    },
    {
      name: "cantidad_pliego_resma",
      label: "Cantidad de Producto",
    },
    {
      name: "ancho_producto",
      label: "Ancho Producto",
    },
    {
      name: "largo_producto",
      label: "Largo Producto",
    },
    {
      name: "fecha_estimada_entrega",
      label: "Fecha Estimada de Entrega",
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "stacked",
    selectableRows: "none",
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => <React.Fragment></React.Fragment>,
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
