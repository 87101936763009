import React, { useContext, useState } from "react";
// import {
//   Grid,
//   Hidden,
//   Paper,
//   Avatar,
//   Typography,
//   TextField,
//   FormControlLabel,
//   Checkbox,
//   Button,
//   Link,
//   Box,
//   CircularProgress,
// } from '@material-ui/core';

// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { StyledDiv } from "./styles";

import { useInputValue } from "../../hooks/useInputValue";
import { useFetch } from "../../hooks/useFetch";

import { UserContext } from "../../contexts/UserContext";

export const Login = () => {
  const colores = [
    "#004d9f",
    "#068189",
    "#0cad49",
    "#b8d12e",
    "#f8d91a",
    "#f08e37",
    "#ea4136",
    "#e50088",
    "#842183",
    "#1b1464",
  ];

  const email = useInputValue("");
  const password = useInputValue("");
  const { loading, getData } = useFetch();
  const { auth, user, setRole } = useContext(UserContext);
  const [error, setError] = useState(undefined);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let validation = 0;
    if (!email.value) {
      email.setError("Campo requerido");
      validation++;
    }
    if (!password.value) {
      password.setError("Campo requerido");
      validation++;
    }
    if (!validation) {
      setError(undefined);
      const formData = new FormData(event.target);
      const response = await getData({
        source: "/users/signin",
        body: formData,
        method: "POST",
      });
      if (response.status === 200) {
        auth.setItem(response.data.token);
        user.setItem(response.data.user);
        setRole(response.data.role);
      } else {
        setError(response.message);
      }
    }
  };

  return (
    <StyledDiv>
      <div className="container-fluid">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 col-lg-6 text-center p-0 a">
            <div className="header">
              <img
                className="img-fluid h-100"
                src="/assets/images/logo_blanco.png"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <h1 className="title align-item-center justify-content-center">
                <div className="color-bar">
                  {colores.map((color) => (
                    <div key={color} style={{ background: color }}></div>
                  ))}
                </div>
                <div className="text w-100 pt-2">Inicia sesión</div>
              </h1>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="Correo o RUC *"
                  value={email.value}
                  onChange={email.onChange}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="Contraseña*"
                  value={password.value}
                  onChange={password.onChange}
                />
              </div>
              <div className="form-group justify-content-center">
                <label className="">
                  <input
                    className="form-control"
                    type="radio"
                    name="recuerdame"
                  />
                  Recuérdame
                </label>
              </div>
              <div className="buttons">
                <button className="btn btn-primary badge-pill">ENTRAR</button>
              </div>
              <a href="">Recuperar Contraseña</a>
            </form>
            <footer>
              <span>IMPAL © 2020. Todos los derechos reservados.</span>
            </footer>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6 text-center b p-0">
            {/* <img className="img-fluid" src="/assets/images/inicio.png" /> */}
          </div>
        </div>
      </div>
    </StyledDiv>
  );

  // return (
  //   <StyledGrid container>
  //     <Hidden smDown>
  //       <Grid item md={6} lg={8} className='bg'></Grid>
  //     </Hidden>

  //     <Grid item xs={12} md={6} lg={4} component={Paper} elevation={6} square className='login'>
  //       <Avatar>
  //         <LockOutlinedIcon />
  //       </Avatar>

  //       <Typography component='h1' variant='h5'>
  //         Inicio de Sesion
  //       </Typography>

  //       <form noValidate onSubmit={handleSubmit}>
  //         <TextField
  //           variant='outlined'
  //           margin='normal'
  //           required
  //           fullWidth
  //           id='email'
  //           name='email'
  //           label='Correo'
  //           type='email'
  //           autoComplete='off'
  //           autoFocus
  //           onChange={email.onChange}
  //           value={email.value}
  //           error={email.error !== ''}
  //           helperText={email.error}
  //           disabled={loading}
  //         />

  //         <TextField
  //           variant='outlined'
  //           margin='normal'
  //           required
  //           fullWidth
  //           id='password'
  //           name='password'
  //           label='Contraseña'
  //           type='password'
  //           onChange={password.onChange}
  //           value={password.value}
  //           error={password.error !== ''}
  //           helperText={password.error}
  //           disabled={loading}
  //         />

  //         <FormControlLabel control={<Checkbox checked={false} value={true} color='primary' />} label='Recuerdame' />

  //         <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
  //           Entrar
  //         </Button>

  //         <Grid container>
  //           <Grid item>
  //             <Link href='#' variant='body2'>
  //               Recuperar contraseña
  //             </Link>
  //           </Grid>
  //         </Grid>

  //         {loading && (
  //           <Box mt={5}>
  //             <Grid container justify='center'>
  //               <CircularProgress />
  //             </Grid>
  //           </Box>
  //         )}

  //         {error && (
  //           <Box mt={5}>
  //             <Typography variant='body1' color='error' align='center'>
  //               {error}
  //             </Typography>
  //           </Box>
  //         )}

  //         <Box mt={5}>
  //           <Copyright />
  //         </Box>
  //       </form>
  //     </Grid>
  //   </StyledGrid>
  // );
};

// function Copyright() {
//   return (
//     <Typography variant='body2' color='textSecondary' align='center'>
//       {'Copyright © '}
//       <Link color='inherit' href='https://material-ui.com/'>
//         IMPAL
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }
