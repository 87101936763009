import React, { useState, useEffect, useCallback, Fragment, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography, FormControlLabel, TextField } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import swal from 'sweetalert';

import { StyledFile } from './styles';

import { CustomToolbar } from '../../../components/MyDataTable/CustomToolbar';
import { MyPaper } from '../../../components/MyPaper';
import { MyDatePicker } from '../../../components/MyDatePicker';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';
import { MyAutocomplete } from '../../../components/MyAutocomplete';
import { UserContext } from '../../../contexts/UserContext';

export const OrderRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { BASE_URL } = useContext(UserContext);

  // Controladores para los campos de formulario
  const cliente = useInputValue('');
  const fechaIngreso = useInputValue(null);
  const fechaSolicitadaEntrega = useInputValue(null);
  const descripcion = useInputValue('');
  const lugarEntrega = useInputValue('');
  const observaciones = useInputValue('');
  const [documento, setDocumento] = useState(null);
  const [preview, setPreview] = useState(null);
  const [nombreDocumento, setNombreDocumento] = useState(null);
  const [trabajos, setTrabajos] = useState([]);
  const [codigoOption, setCodigoOption] = useState([]);
  const [razonSocialOption, setRazonSocialOption] = useState([]);

  // Funciones y Callbacks
  const setDataF = (pedido, trabajosResponse) => {
      cliente.setValue(pedido.cliente);
      fechaIngreso.setValue(pedido.fecha_ingreso);
      fechaSolicitadaEntrega.setValue(pedido.fecha_solicitada_entrega);
      descripcion.setValue(pedido.descripcion);
      lugarEntrega.setValue(pedido.lugar_entrega);
      observaciones.setValue(pedido.observaciones);
      setTrabajos(trabajosResponse);
      if (pedido.documento) {
        setNombreDocumento(pedido.documento);
        setPreview(`${BASE_URL}/documents/${pedido.documento}`);
      }
    },
    setData = useCallback(setDataF, []);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      const [clientes, pedido, trabajosResponse] = await Promise.all([
        getData({ source: '/clients/', bg: true }),
        id && getData({ source: `/orders/${id}` }),
        id && getData({ source: `/orders/${id}/jobs` }),
      ]);
      if (clientes.status === 200) {
        setCodigoOption(clientes.data.map(cliente => ({ value: cliente.id, label: cliente.codigo, razonSocial: cliente.razon_social })));
        setRazonSocialOption(clientes.data.map(cliente => ({ value: cliente.id, label: cliente.razon_social, codigo: cliente.codigo })));
      }
      id && pedido.status === 200 && trabajosResponse.status === 200 && setData(pedido.data, trabajosResponse.data);
    })();
  }, [id, getData, setData]);

  const fileToBlob = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    if (!trabajos.length) {
      swal('Debe ingresar al menos 1 trabajo.', { icon: 'error' });
      return;
    }

    if (documento && documento.type !== 'application/pdf') {
      swal('El documento debe ser | pdf |', { icon: 'error' });
      return;
    }

    if (!cliente.value || !fechaIngreso.value || !fechaSolicitadaEntrega.value || !descripcion.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      cliente: cliente.value,
      fechaIngreso: fechaIngreso.value,
      fechaSolicitadaEntrega: fechaSolicitadaEntrega.value,
      descripcion: descripcion.value,
      lugarEntrega: lugarEntrega.value,
      observaciones: observaciones.value,
      trabajos: trabajos,
      documento: documento ? preview : null,
      nombreDocumento: nombreDocumento,
    };
    setError(undefined);
    let message = undefined;
    let response = null;
    if (id) {
      message = 'Pedido actualizado con exito.';
      response = await getData({ source: `/orders/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Pedido registrado con exito.';
      response = await getData({ source: '/orders/store', body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyAutocomplete name='codigo' label='Codigo' options={codigoOption} autoFocus={true} controller={cliente} disabled={loading} />

          <MyAutocomplete name='cliente' label='Cliente' options={razonSocialOption} controller={cliente} disabled={loading} />

          <MyDatePicker name='fechaIngreso' label='Fecha de Ingreso' controller={fechaIngreso} required={true} disabled={loading} />

          <MyDatePicker
            name='fechaSolicitadaEntrega'
            label='Fecha Solicitada de Entrega'
            controller={fechaSolicitadaEntrega}
            required={true}
            disabled={loading}
          />

          <MyInput name='descripcion' label='Descripcion' toCase='upper' controller={descripcion} required={true} disabled={loading} />

          <MyInput name='lugarEntrega' label='Lugar Entrega' toCase='upper' controller={lugarEntrega} disabled={loading} />

          <MyInput
            name='observaciones'
            label='Observaciones'
            toCase='upper'
            controller={observaciones}
            rows={4}
            md={12}
            disabled={loading}
          />

          <Grid item xs={12} md={6}>
            <StyledFile>
              <label>Documento</label>
              <input
                type='file'
                name='documento'
                onChange={async e => {
                  setDocumento(e.target.files[0]);
                  setPreview(await fileToBlob(e.target.files[0]));
                }}
              />
            </StyledFile>
          </Grid>

          {preview && (
            <Grid item xs={12}>
              <object width='100%' height='500' data={preview}>
                <p>Error al mostrar el documento.</p>
              </object>
            </Grid>
          )}

          <Grid item xs={12}>
            <DataTable title='Trabajos' data={trabajos} setData={setTrabajos} />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data, setData }) => {
  const handleClick = () => {
    setData([...data, { numero_cotizacion: '', numero_orden_compra: '', numero_orden_venta: '', descripcion: '' }]);
  };

  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const deleteRow = rowsDeleted => {
    const tmp = data;
    const sorted = rowsDeleted.sort((a, b) => a.index - b.index);
    sorted.reverse().forEach(item => tmp.splice(item.index, 1));
    setData(tmp);
  };

  const columns = [
    {
      name: 'numero_cotizacion',
      label: '#Cotizacion',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'numero_cotizacion', event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: 'numero_orden_compra',
      label: '#Orden de Compra',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <FormControlLabel
              control={<TextField value={value || ''} disabled={!data[index].numero_cotizacion && true} />}
              onChange={event => {
                updateValue(event.target.value);
                updateData(tableMeta.rowIndex, 'numero_orden_compra', event.target.value);
              }}
            />
          );
        },
      },
    },
    {
      name: 'numero_orden_venta',
      label: '#Orden de Venta',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <FormControlLabel
              control={
                <TextField value={value || ''} disabled={(!data[index].numero_cotizacion || !data[index].numero_orden_compra) && true} />
              }
              onChange={event => {
                updateValue(event.target.value);
                updateData(tableMeta.rowIndex, 'numero_orden_venta', event.target.value);
              }}
            />
          );
        },
      },
    },
    {
      name: 'descripcion',
      label: 'Descripcion',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'descripcion', event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: 'etapa',
      label: 'Etapa',
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    customToolbar: () => <CustomToolbar tooltip='Agregar trabajo' handleClick={handleClick} />,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
    onRowsDelete: rowsDeleted => {
      deleteRow(rowsDeleted.data);
    },
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};
