import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import { useFetch } from '../../hooks/useFetch';

export const ProductionOrder = () => {
  const { getData } = useFetch();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getData({ source: `/dashboard/production-order` });
      response.status === 200 && setData(response.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <MUIDataTable title='Ordenes de Produccion' data={data} columns={columns} options={options} />
    </Grid>
  );
};

const columns = [
  {
    name: 'fecha_ingreso',
    label: 'Fecha de Ingreso',
    options: {
      filter: true,
    },
  },
  {
    name: 'fecha_solicitada_entrega',
    label: 'Fecha Solicitada de Entrega',
    options: {
      filter: true,
    },
  },
  {
    name: 'razon_social',
    label: 'Cliente',
    options: {
      filter: true,
    },
  },
  {
    name: 'codigo_producto',
    label: 'Codigo del Producto',
    options: {
      filter: true,
    },
  },
  {
    name: 'descripcion',
    label: 'Descripcion',
    options: {
      filter: true,
    },
  },
  {
    name: 'numero_orden_produccion',
    label: 'OP',
    options: {
      filter: true,
    },
  },
  {
    name: 'etapa',
    label: 'Etapa',
    options: {
      filter: true,
    },
  },
  // {
  //   name: 'estado',
  //   label: 'Estado de etapa',
  //   options: {
  //     filter: true,
  //   },
  // },
];

const options = {
  filter: true,
  print: false,
  selectableRows: 'none',
  download: false,
  rowsPerPage: 15,
  filterType: 'dropdown',
  responsive: 'scrollFullHeight',
};
