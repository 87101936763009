import React from 'react';
import { Grid, TextField } from '@material-ui/core';

export const MyInput = ({
  name,
  label,
  required = false,
  type = 'text',
  autoComplete = 'off',
  autoFocus = false,
  controller,
  disabled,
  toCase = undefined,
  helperText = '',
  md = 6,
  rows = 1
}) => {
  return (
    <Grid item xs={12} md={md}>
      <TextField
        name={name}
        id={name}
        label={label}
        required={required}
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        value={controller.value}
        onChange={event => controller.onChange(event, toCase)}
        error={controller.error !== ''}
        helperText={controller.error || helperText}
        disabled={disabled}
        variant='outlined'
        margin='normal'
        fullWidth
        multiline={rows > 1}
        rows={rows}
      />
    </Grid>
  );
};
