import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';
import { MyAutocomplete } from '../../../components/MyAutocomplete';

export const ProductRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const cliente = useInputValue('');
  const codigo = useInputValue('');
  const descripcion = useInputValue('');
  const [codigoOption, setCodigoOption] = useState([]);
  const [razonSocialOption, setRazonSocialOption] = useState([]);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      const [clientes, producto] = await Promise.all([
        getData({ source: '/clients/', bg: true }),
        id && getData({ source: `/products/${id}` }),
      ]);
      if (clientes.status === 200) {
        setCodigoOption(clientes.data.map(cliente => ({ value: cliente.id, label: cliente.codigo, razonSocial: cliente.razon_social })));
        setRazonSocialOption(clientes.data.map(cliente => ({ value: cliente.id, label: cliente.razon_social, codigo: cliente.codigo })));
      }
      if (id && producto.status === 200) {
        cliente.setValue(producto.data.cliente);
        codigo.setValue(producto.data.codigo);
        descripcion.setValue(producto.data.descripcion);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!cliente.value || !codigo.value || !descripcion.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      cliente: cliente.value,
      codigo: codigo.value,
      descripcion: descripcion.value,
    };
    setError(undefined);
    let message = undefined;
    let response = null;
    if (id) {
      message = 'Producto actualizado con exito.';
      response = await getData({ source: `/products/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Producto registrado con exito.';
      response = await getData({ source: '/products', body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyAutocomplete name='codigo' label='Codigo' options={codigoOption} autoFocus={true} controller={cliente} disabled={loading} />
          <MyAutocomplete name='cliente' label='Cliente' options={razonSocialOption} controller={cliente} disabled={loading} />
          <MyInput name='codigo' label='Codigo de Producto' type='number' controller={codigo} required={true} disabled={loading} />
          <MyInput
            name='descripcion'
            label='Descripcion'
            toCase='upper'
            controller={descripcion}
            rows={4}
            md={12}
            disabled={loading}
          />

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
