import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const ClientMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'codigo',
      label: 'Codigo',
      options: {
        filter: true,
      },
    },
    {
      name: 'razon_social',
      label: 'Razon Social',
      options: {
        filter: true,
      },
    },
    {
      name: 'ruc',
      label: 'RUC',
      options: {
        filter: true,
      },
    },
    {
      name: 'direccion_fiscal',
      label: 'Direccion Fiscal',
      options: {
        filter: true,
      },
    },
    {
      name: 'pais',
      label: 'Pais',
      options: {
        filter: true,
      },
    },
    {
      name: 'departamento',
      label: 'Departamento',
      options: {
        filter: true,
      },
    },
    {
      name: 'provincia',
      label: 'Provincia',
      options: {
        filter: true,
      },
    },
    {
      name: 'distrito',
      label: 'Distrito',
      options: {
        filter: true,
      },
    },
    {
      name: 'telefono_principal',
      label: 'Telefono',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Clientes' source='/clients' customColumns={customColumns} />;
};
