import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const ApprovalMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'codigo',
      label: 'Codigo',
      options: {
        filter: true,
      },
    },
    {
      name: 'razon_social',
      label: 'Cliente',
      options: {
        filter: true,
      },
    },
    {
      name: 'descripcion',
      label: 'Descripcion',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_ingreso',
      label: 'Fecha de Ingreso',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_solicitada_entrega',
      label: 'Fecha Solicitada de Entrega',
      options: {
        filter: true,
      },
    },
    {
      name: 'visto_bueno',
      label: 'Visto Bueno',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Visto Bueno' source='/approval' customColumns={customColumns} canDelete={false} canRegister={false} />;
};
