import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const RoleMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Roles' source='/roles' customColumns={customColumns} />;
};
