import React, { useState, useContext } from 'react'
import { CssBaseline, Grid } from '@material-ui/core';

import {
    StyledDiv,
    StyledMain,
    StyledContainer,
} from './styles'

import { MyAppBar } from '../MyAppBar'
import { MyDrawer } from '../MyDrawer';


import { LayoutContext } from '../../contexts/LayoutContext'

export const Layout = ({children}) => {

    const { drawer } = useContext(LayoutContext)
    const [openDrawer, setOpenDrawer] = useState(drawer.value)

    React.useEffect(() => {
        drawer.setItem(openDrawer)
    }, [openDrawer, drawer])

    return (

        <StyledDiv>

            <CssBaseline />

            <MyDrawer openDrawer={openDrawer} />

            <StyledContainer>

                <MyAppBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

                <StyledMain>

                    <Grid container direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={2}
                    >

                        {children}
                    
                    </Grid>

                </StyledMain>

            </StyledContainer>
            
        </StyledDiv>

    );

}