import React from "react";
import {
  Tooltip,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

export const CustomToolbar = ({ tooltip, handleClick }) => {

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

}