import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import swal from "sweetalert";

import { CustomToolbar } from "../../../components/MyDataTable/CustomToolbar";
import { MyPaper } from "../../../components/MyPaper";
import { MyDatePicker } from "../../../components/MyDatePicker";
import { MyInput } from "../../../components/MyInput";
import { MySelect } from "../../../components/MySelect";

import { useInputValue } from "../../../hooks/useInputValue";
import { useFetch } from "../../../hooks/useFetch";
import { MyAutocomplete } from "../../../components/MyAutocomplete";

export const SaleOrderRegister = ({ id }) => {
  const [title, setTitle] = useState("Registrar");
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const ordenVenta = useInputValue("");
  const fecha = useInputValue(null);
  const cliente = useInputValue("");
  const numeroCotizacion = useInputValue("");
  const numeroOrdenCompra = useInputValue("");
  const numeroPresupuestaAprobado = useInputValue("");
  const vendedor = useInputValue("");
  const condicionPago = useInputValue("");
  const monedaVenta = useInputValue("");
  const tipoTrabajo = useInputValue("");
  const tipoEntrega = useInputValue("");
  const tipoCambio = useInputValue("");
  const observacion = useInputValue("");

  const [clientes, setClientes] = useState([]);
  const [tCliente, setTCliente] = useState([]);
  const [codigoOption, setCodigoOption] = useState([]);
  const [razonSocialOption, setRazonSocialOption] = useState([]);
  const [tTrabajo, setTTrabajo] = useState([]);

  const _monedas = [{ name: "DOLARES" }, { name: "SOLES" }];
  const [_tipoTrabajo, set_tipoTrabajo] = useState([]);
  const _tipoEntrega = [{ name: "DESPACHO" }, { name: "RECOGE" }];

  // Controladores de cambio
  useEffect(() => {
    if (cliente.value && clientes) {
      const tmp = clientes.find((c) => c.id === cliente.value);
      setTCliente([tmp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente.value]);

  // Inicializando
  useEffect(() => {
    id && setTitle("Editar");
    (async function () {
      const [clientes, tiposTrabajos, _ordenVenta] = await Promise.all([
        getData({ source: "/clients/", bg: true }),
        getData({ source: `/jobs/types`, bg: true }),
        id && getData({ source: `/sale-order/${id}` }),
      ]);
      if (clientes.status === 200) {
        setClientes(clientes.data);
        setCodigoOption(
          clientes.data.map((cliente) => ({
            value: cliente.id,
            label: cliente.codigo,
            razonSocial: cliente.razon_social,
          }))
        );
        setRazonSocialOption(
          clientes.data.map((cliente) => ({
            value: cliente.id,
            label: cliente.razon_social,
            codigo: cliente.codigo,
          }))
        );
      }
      if (tiposTrabajos.status === 200) set_tipoTrabajo(tiposTrabajos.data);
      if (id && _ordenVenta.status === 200) {
        const { saleOrder, details } = _ordenVenta.data;
        ordenVenta.setValue(saleOrder.numero);
        fecha.setValue(saleOrder.fecha || null);
        cliente.setValue(saleOrder.cliente);
        numeroCotizacion.setValue(saleOrder.numero_cotizacion || "");
        numeroOrdenCompra.setValue(saleOrder.numero_orden_compra || "");
        numeroPresupuestaAprobado.setValue(
          saleOrder.numero_presupuesto_aprobado || ""
        );
        vendedor.setValue(saleOrder.vendedor || "");
        condicionPago.setValue(saleOrder.condicion_pago || "");
        monedaVenta.setValue(saleOrder.moneda_venta || "");
        tipoTrabajo.setValue(saleOrder.tipo_trabajo || "");
        tipoEntrega.setValue(saleOrder.tipo_entrega || "");
        tipoCambio.setValue(saleOrder.tipo_cambio || "");
        observacion.setValue(saleOrder.observacion || "");

        setTTrabajo(details);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getData]);

  // Manejador de envio del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!ordenVenta.value || !fecha.value || !cliente.value) {
      swal("Debe llenar los campos requeridos.", { icon: "error" });
      return;
    }
    if (!tTrabajo.length) {
      swal("Debe ingresar al menos 1 item.", { icon: "error" });
      return;
    }
    const body = {
      ordenVenta: ordenVenta.value,
      fecha: fecha.value,
      cliente: cliente.value,
      numeroCotizacion: numeroCotizacion.value,
      numeroOrdenCompra: numeroOrdenCompra.value,
      numeroPresupuestaAprobado: numeroPresupuestaAprobado.value,
      vendedor: vendedor.value,
      condicionPago: condicionPago.value,
      monedaVenta: monedaVenta.value,
      tipoTrabajo: tipoTrabajo.value,
      tipoEntrega: tipoEntrega.value,
      tipoCambio: tipoCambio.value,
      observacion: observacion.value,
      trabajos: tTrabajo,
    };
    setError(undefined);
    let message = undefined;
    let response = null;
    if (id) {
      message = "Pedido actualizado con exito.";
      response = await getData({
        source: `/sale-order/${id}`,
        body: body,
        json: true,
        method: "PUT",
      });
    } else {
      message = "Pedido registrado con exito.";
      response = await getData({
        source: "/sale-order",
        body: body,
        json: true,
        method: "POST",
      });
    }
    if (response.status === 200) {
      await swal(message, { icon: "success" });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyInput
            name="ordenVenta"
            label="Orden de Venta"
            type="number"
            controller={ordenVenta}
            required={true}
            disabled={loading}
          />
          <MyDatePicker
            name="fecha"
            label="Fecha"
            controller={fecha}
            required={true}
            disabled={loading}
          />

          <MyAutocomplete
            name="codigo"
            label="Codigo"
            options={codigoOption}
            controller={cliente}
            disabled={loading}
          />
          <MyAutocomplete
            name="cliente"
            label="Cliente"
            options={razonSocialOption}
            controller={cliente}
            disabled={loading}
          />
          <Grid item xs={12}>
            <TCliente title="Datos del cliente" data={tCliente} />
          </Grid>

          <MyInput
            name="numeroCotizacion"
            label="Cotizacion Nro"
            type="number"
            controller={numeroCotizacion}
            required={true}
            disabled={loading}
          />
          <MyInput
            name="numeroOrdenCompra"
            label="Orden de Compra Nro"
            type="number"
            controller={numeroOrdenCompra}
            required={true}
            disabled={loading}
          />
          <MyInput
            name="numeroPresupuestaAprobado"
            label="Presupuesto Aprobado Nro"
            type="number"
            controller={numeroPresupuestaAprobado}
            disabled={loading}
          />
          <MyInput
            name="vendedor"
            label="Vendedor"
            controller={vendedor}
            required={true}
            disabled={loading}
          />
          <MyInput
            name="condicionPago"
            label="Condicion de Pago"
            controller={condicionPago}
            required={true}
            disabled={loading}
          />
          <MySelect
            name="monedaVenta"
            label="Moneda de Venta"
            controller={monedaVenta}
            required={true}
            disabled={loading}
            data={_monedas}
          />
          <MySelect
            name="tipoTrabajo"
            label="Tipo de trabajo"
            controller={tipoTrabajo}
            required={true}
            disabled={loading}
            data={_tipoTrabajo}
          />
          <MySelect
            name="tipoEntrega"
            label="Tipo de Entrega"
            controller={tipoEntrega}
            required={true}
            disabled={loading}
            data={_tipoEntrega}
          />
          <MyInput
            name="tipoCambio"
            label="Tipo de Cambio"
            type="number"
            controller={tipoCambio}
            disabled={loading}
          />
          <MyInput
            name="observacion"
            label="Observaciones"
            toCase="upper"
            controller={observacion}
            rows={4}
            md={12}
            disabled={loading}
          />

          <Grid item xs={12}>
            <TTrabajo
              title="Items"
              data={tTrabajo}
              setData={setTTrabajo}
              cambio={tipoCambio.value}
            />
          </Grid>

          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid
              container
              item
              justify="flex-start"
              alignItems="center"
              xs={12}
              md={6}
            >
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant="h6" color="textSecondary">
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant="h6" color="error">
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const TCliente = ({ title, data }) => {
  const columns = [
    {
      name: "codigo",
      label: "Codigo",
    },
    {
      name: "ruc",
      label: "RUC",
    },
    {
      name: "direccion_fiscal",
      label: "Dir. Fiscal",
    },
    {
      name: "direccion_entrega",
      label: "Dir. Entrega",
    },
    {
      name: "contacto_comercial",
      label: "Contacto",
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "stacked",
    selectableRows: "none",
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => <React.Fragment></React.Fragment>,
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

const TTrabajo = ({ title, data, setData, cambio = 0 }) => {
  const handleClick = () => {
    setData([
      ...data,
      {
        cantidad: "",
        unidad_medida: "",
        descripcion: "",
        modelo: "",
        acabado: "",
        precio_dolares: "",
        subtotal_dolares: "",
        precio_soles: "",
        subtotal_soles: "",
      },
    ]);
  };

  const getTotal = () => {
    const tmp = { dolares: 0, soles: 0 };
    data.forEach((i) => {
      tmp.dolares += Number(i.subtotal_dolares);
      tmp.soles += Number(i.subtotal_soles);
    });
    return tmp;
  };

  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const deleteRow = (rowsDeleted) => {
    const tmp = data;
    const sorted = rowsDeleted.sort((a, b) => a.index - b.index);
    sorted.reverse().forEach((item) => tmp.splice(item.index, 1));
    setData(tmp);
  };

  const columns = [
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField type="number" value={value || ""} />}
            onChange={(event) => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, "cantidad", event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: "unidad_medida",
      label: "UNIDAD DE MEDIDA",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ""} />}
            onChange={(event) => {
              updateValue(event.target.value);
              updateData(
                tableMeta.rowIndex,
                "unidad_medida",
                event.target.value
              );
            }}
          />
        ),
      },
    },
    {
      name: "descripcion",
      label: "DESCRIPCION",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ""} />}
            onChange={(event) => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, "descripcion", event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: "modelo",
      label: "MODELO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ""} />}
            onChange={(event) => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, "modelo", event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: "acabado",
      label: "ACABADO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ""} />}
            onChange={(event) => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, "acabado", event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: "precio_dolares",
      label: "PRECIO $",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <FormControlLabel
              control={<TextField value={data[index].precio_dolares || ""} />}
              onChange={(event) => {
                updateValue(event.target.value);
                updateData(
                  tableMeta.rowIndex,
                  "precio_dolares",
                  event.target.value
                );
                updateData(
                  index,
                  "precio_soles",
                  (event.target.value * cambio).toFixed(4)
                );
              }}
            />
          );
        },
      },
    },
    {
      name: "subtotal_dolares",
      label: "SUB-TOTAL $",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          const tmp = (
            data[index].cantidad * data[index].precio_dolares
          ).toFixed(4);
          updateData(index, "subtotal_dolares", tmp);
          return (
            <FormControlLabel
              control={<TextField value={tmp || ""} />}
              disabled={true}
            />
          );
        },
      },
    },
    {
      name: "precio_soles",
      label: "PRECIO S/",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <FormControlLabel
              control={<TextField value={data[index].precio_soles || ""} />}
              onChange={(event) => {
                updateValue(event.target.value);
                updateData(index, "precio_soles", event.target.value);
                updateData(
                  index,
                  "precio_dolares",
                  (event.target.value / cambio).toFixed(4)
                );
              }}
            />
          );
        },
      },
    },
    {
      name: "subtotal_soles",
      label: "SUB-TOTAL S/",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          const tmp = Number(
            data[index].cantidad * data[index].precio_soles
          ).toFixed(4);
          updateData(index, "subtotal_soles", tmp);
          return (
            <FormControlLabel
              control={<TextField value={tmp || ""} />}
              disabled={true}
            />
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "stacked",
    rowsPerPage: 50,
    customToolbar: () => (
      <CustomToolbar tooltip="Agregar trabajo" handleClick={handleClick} />
    ),
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <tbody
          style={{
            float: "right",
            margin: "10px",
            padding: "10px",
            border: "1px solid black",
          }}
        >
          <tr>
            <td width="100">
              <b>SUB TOTAL $:</b>
            </td>
            <td
              width="150"
              style={{ textAlign: "right", paddingRight: "20px" }}
            >
              {getTotal().dolares.toFixed(4)}
            </td>
            <td width="100">
              <b>SUB TOTAL S/:</b>
            </td>
            <td
              width="150"
              style={{ textAlign: "right", paddingRight: "20px" }}
            >
              {getTotal().soles.toFixed(4)}
            </td>
          </tr>
          <tr>
            <td>
              <b>IGV $ 18%:</b>
            </td>
            <td style={{ textAlign: "right", paddingRight: "20px" }}>
              {(getTotal().dolares * 0.18).toFixed(4)}
            </td>
            <td>
              <b>IGV S/ 18%:</b>
            </td>
            <td style={{ textAlign: "right", paddingRight: "20px" }}>
              {(getTotal().soles * 0.18).toFixed(4)}
            </td>
          </tr>
          <tr>
            <td>
              <b>TOTAL $:</b>
            </td>
            <td style={{ textAlign: "right", paddingRight: "20px" }}>
              {(getTotal().dolares + getTotal().dolares * 0.18).toFixed(4)}
            </td>
            <td>
              <b>TOTAL S/:</b>
            </td>
            <td style={{ textAlign: "right", paddingRight: "20px" }}>
              {(getTotal().soles + getTotal().soles * 0.18).toFixed(4)}
            </td>
          </tr>
        </tbody>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      deleteRow(rowsDeleted.data);
    },
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
