import React, { createContext } from 'react'

import { useLocalStorage } from '../hooks/useLocalStorage'

export const LayoutContext = createContext()

const Provider = ({ children }) => {
  const drawer = useLocalStorage('drawer')
  const menu = useLocalStorage('menu')

  const value = {
    drawer,
    menu,
  }

  return (
    <LayoutContext.Provider value={value}>
      { children }
    </LayoutContext.Provider>
  )
}

export default {
    Provider,
    Consumer: LayoutContext.Consumer
}