import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Input, InputLabel, MenuItem, FormControl, Select, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export const MyMultiSelect = ({ data, setData, label, options = [] }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = event => {
    setData(event.target.value);
  };

  return (
    <Grid item xs={12}>
      <FormControl variant='outlined' fullWidth margin='normal'>
        <InputLabel id='demo-mutiple-chip-label'>{label}</InputLabel>
        <Select
          labelId='demo-mutiple-chip-label'
          id='demo-mutiple-chip'
          multiple
          value={data}
          onChange={handleChange}
          input={<Input id='select-multiple-chip' />}
          renderValue={data => (
            <div className={classes.chips}>
              {data.map(item => (
                <Chip key={item.id || item.name} label={item.name} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {options.map(option => (
            <MenuItem key={option.id || option.name} value={option} style={getStyles(option.name, data, theme)}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
