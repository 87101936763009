import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MySelect } from '../../../components/MySelect';
import { MyDatePicker } from '../../../components/MyDatePicker';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';
import { useDialog } from '../../../hooks/useDialog';

export const BillingRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { success } = useDialog();

  // Controladores para los campos de formulario
  const ordenProduccion = useInputValue('');
  const cliente = useInputValue('');
  const descripcion = useInputValue('');
  const numeroFactura = useInputValue('');
  const fechaFactura = useInputValue(null);
  const estado = useInputValue('');

  // Inicializando
  useEffect(() => {
    if (id) {
      setTitle('Editar');
      (async function() {
        const factura = await getData({ source: `/billing/${id}` });
        if (factura.status === 200) {
          ordenProduccion.setValue(factura.data.numero_orden_produccion);
          cliente.setValue(factura.data.razon_social);
          descripcion.setValue(factura.data.descripcion);
          numeroFactura.setValue(factura.data.numero_factura || '');
          fechaFactura.setValue(factura.data.fecha || null);
          estado.setValue(factura.data.estado || '');
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!numeroFactura.value || !fechaFactura.value || !descripcion.value || !estado.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      numeroFactura: numeroFactura.value,
      fechaFactura: fechaFactura.value,
      estado: estado.value,
    };
    const message = 'Informacion actualizada con exito.';
    const response = await getData({ source: `/billing/${id}`, body: body, json: true, method: 'PUT' });

    if (response.status === 200) {
      success(message)
        .then()
        .then(() => window.history.back());
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyInput name='ordenProduccion' label='Orden de Produccion' controller={ordenProduccion} disabled={true} />
          <MyInput name='cliente' label='Cliente' controller={cliente} disabled={true} />
          <MyInput
            name='descripcion'
            label='Descripcion del Producto'
            toCase='upper'
            controller={descripcion}
            rows={4}
            md={12}
            disabled={true}
          />
          <MyInput
            name='numeroFactura'
            label='Numero de Factura'
            toCase='upper'
            required={true}
            controller={numeroFactura}
            disabled={loading}
          />
          <MyDatePicker name='fechaFactura' label='Fecha de la Factura' required={true} controller={fechaFactura} disabled={loading} />
          <MySelect
            name='estado'
            label='Estado'
            required={true}
            controller={estado}
            disabled={loading}
            data={[{ name: 'PENDIENTE' }, { name: 'FACTURADO' }]}
          />

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
