import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyTransferList } from '../../../components/TransferList';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

export const PrePressRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const descripcion = useInputValue('');
  const codigoProducto = useInputValue('');
  const numeroOrdenProduccion = useInputValue('');
  const artes = useInputValue('');
  const artesEstado = useInputValue('');
  const carpetaAprobacion = useInputValue('');
  const placas = useInputValue('');
  const observaciones = useInputValue('');

  const [opciones, setOpciones] = useState([]);
  const [opcionesPreprensa, setOpcionesPreprensa] = useState([]);

  // Data para los selects
  const [artesOption, setArtesOption] = useState([]);
  const [artesEstadoOption, setArtesEstadoOption] = useState([]);
  const [carpetaAprobacionOption, setCarpetaAprobacionOption] = useState([]);
  const [placasOption, setPlacasOption] = useState([]);

  // Funciones y Callbacks
  const setDataF = async trabajo => {
      descripcion.setValue(trabajo.descripcion);
      codigoProducto.setValue(trabajo.codigo || '');
      numeroOrdenProduccion.setValue(trabajo.numero_orden_produccion);
      artes.setValue(trabajo.artes || '');
      artesEstado.setValue(trabajo.artes_estado || '');
      carpetaAprobacion.setValue(trabajo.carpeta_aprobacion || '');
      placas.setValue(trabajo.placas || '');
      observaciones.setValue(trabajo.observaciones_preprensa || '');
      if (trabajo.artes) {
        const artesResponse = await getData({ source: `/prepress/arts/${trabajo.artes}`, bg: true });
        artesResponse.status === 200 && setArtesEstadoOption(artesResponse.data);
      }
    },
    setData = useCallback(setDataF, []);

  const handleArtes = async event => {
    const artesResponse = await getData({ source: `/prepress/arts/${event.target.value}`, bg: true });
    artesResponse.status === 200 && setArtesEstadoOption(artesResponse.data);
  };

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      if (id) {
        const [trabajo, artesResponse, carpetaAprobacionResponse, placasResponse, _opciones, preprensaOpciones] = await Promise.all([
          getData({ source: `/jobs/${id}/edit` }),
          getData({ source: `/prepress/arts`, bg: true }),
          getData({ source: `/prepress/approval-folder`, bg: true }),
          getData({ source: `/prepress/plate`, bg: true }),
          getData({ source: `/prepress/options`, bg: true }),
          getData({ source: `/prepress/prepress-options/${id}`, bg: true }),
        ]);
        trabajo.status === 200 && setData(trabajo.data);
        artesResponse.status === 200 && setArtesOption(artesResponse.data);
        carpetaAprobacionResponse.status === 200 && setCarpetaAprobacionOption(carpetaAprobacionResponse.data);
        placasResponse.status === 200 && setPlacasOption(placasResponse.data);
        _opciones.status === 200 && setOpcionesPreprensa(_opciones.data);
        preprensaOpciones.status === 200 && setOpciones(preprensaOpciones.data);
      }
    })();
  }, [id, getData, setData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    if (!artes.value || !artesEstado.value || !carpetaAprobacion.value || !placas.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      artes: artes.value,
      artesEstado: artesEstado.value,
      carpetaAprobacion: carpetaAprobacion.value,
      placas: placas.value,
      observaciones: placas.value,
      opciones: opciones,
    };
    setError(undefined);
    const response = await getData({ source: `/prepress/${id}`, body: body, json: true, method: 'PUT' });
    if (response.status === 200) {
      await swal('Informacion actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyInput name='descripcion' label='Descripcion' controller={descripcion} disabled={true} />
          <MyInput name='codigoProducto' label='Codigo de Producto' controller={codigoProducto} disabled={true} />
          <MyInput name='numeroOrdenProduccion' label='#Order de Produccion' controller={numeroOrdenProduccion} disabled={true} />
          <MySelect
            name='artes'
            label='Artes'
            required={true}
            controller={artes}
            disabled={loading}
            data={artesOption}
            handleChange={handleArtes}
          />
          <MySelect
            name='artesEstado'
            label='Artes - Estado'
            required={true}
            controller={artesEstado}
            disabled={loading}
            data={artesEstadoOption}
          />
          <MySelect
            name='carpetaAprobacion'
            label='Carpeta de aprobacion'
            required={true}
            controller={carpetaAprobacion}
            disabled={artesEstado.value === ''}
            data={carpetaAprobacionOption}
          />
          <MySelect
            name='placas'
            label='Placas'
            required={true}
            controller={placas}
            disabled={carpetaAprobacion.value === ''}
            data={placasOption}
          />
          <MyInput
            name='observaciones'
            label='Observaciones'
            toCase='upper'
            controller={observaciones}
            rows={4}
            md={12}
            disabled={loading}
          />

          <MyTransferList data={opciones} setData={setOpciones} options={opcionesPreprensa} />

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
