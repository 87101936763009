import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyDatePicker } from '../../../components/MyDatePicker';
import { DividerTitle } from '../../../components/DividerTitle';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

export const PurchaseLogisticsRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const fechaSolicitud = useInputValue('');
  const ordenCompra = useInputValue('');
  const proveedorSolicitud = useInputValue('');

  const fecha = useInputValue('');
  const proveedor = useInputValue('');
  const guiaRemision = useInputValue('');
  const cantidad = useInputValue('');
  const peso = useInputValue('');
  const kilos = useInputValue('');
  const estado = useInputValue('');
  const observaciones = useInputValue('');
  const [trabajos, setTrabajos] = useState([]);

  const [proveedorOption, setProveedorOption] = useState([]);

  // Funciones y Callbacks
  const setDataF = trabajo => {
      setTrabajos([trabajo]);
      fechaSolicitud.setValue(trabajo.fecha_solicitud || null);
      ordenCompra.setValue(trabajo.orden_compra || '');
      proveedorSolicitud.setValue(trabajo.proveedor_solicitud || '');

      fecha.setValue(trabajo.fecha);
      proveedor.setValue(trabajo.proveedor || '');
      guiaRemision.setValue(trabajo.guia_remision || '');
      cantidad.setValue(trabajo.cantidad || '');
      peso.setValue(trabajo.peso || '');
      kilos.setValue(trabajo.kilos || '');
      estado.setValue(trabajo.estado || '');
      observaciones.setValue(trabajo.observaciones_compras || '');
    },
    setData = useCallback(setDataF, []);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      if (id) {
        const [trabajo, proveedores] = await Promise.all([
          getData({ source: `/purchase-logistics/${id}` }),
          getData({ source: `/purchase-logistics/proveedores` }),
        ]);
        trabajo.status === 200 && setData(trabajo.data);
        proveedores.status === 200 && setProveedorOption(proveedores.data);
      }
    })();
  }, [id, getData, setData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!fechaSolicitud.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    const formData = new FormData(event.target);
    setError(undefined);
    const response = await getData({ source: `/purchase-logistics/${id}`, body: formData, method: 'POST' });
    if (response.status === 200) {
      await swal('Informacion actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <DataTable title='Trabajo' data={trabajos} />
          </Grid>

          <DividerTitle title='SOLICITUD DE MATERIAL' />
          <MyDatePicker name='fechaSolicitud' label='Fecha de Solicitud' required={true} controller={fechaSolicitud} disabled={loading} />
          <MyInput name='ordenCompra' label='Orden de Compra' required={true} controller={ordenCompra} disabled={loading} />
          <MyInput name='proveedorSolicitud' label='Proveedor' controller={proveedorSolicitud} disabled={loading} />

          <DividerTitle title='RECEPCION DE MATERIAL' />
          <MyDatePicker name='fecha' label='Fecha de Ingreso del Material' controller={fecha} disabled={loading} />
          <MySelect name='proveedor' label='Proveedor' controller={proveedor} disabled={loading} data={proveedorOption} />
          <MyInput
            name='guiaRemision'
            label='Guia de Remision'
            required={true}
            controller={guiaRemision}
            toCase='upper'
            disabled={loading}
          />
          <MyInput name='cantidad' label='Cantidad Ingresada' controller={cantidad} type='number' disabled={loading} />
          <MyInput name='peso' label='Peso' controller={peso} type='number' disabled={loading} />
          <MyInput name='kilos' label='Kilos' controller={kilos} type='number' disabled={loading} />

          <DividerTitle title="" />
          <MyInput
            name='observaciones'
            label='Observaciones'
            toCase='upper'
            controller={observaciones}
            rows={4}
            md={12}
            disabled={loading}
          />

          <MySelect
            name='estado'
            label='Estado'
            required={true}
            controller={estado}
            disabled={loading}
            data={[
              { id: 3, name: 'PENDIENTE' },
              { id: 1, name: 'SOLICITADO' },
              { id: 2, name: 'RECEPCIONADO' },
            ]}
          />

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data, setData }) => {
  const columns = [
    {
      name: 'numero_orden_produccion',
      label: '#Orden de Produccion',
    },
    {
      name: 'tipo_trabajo',
      label: 'Tipo de Trabajo',
    },
    {
      name: 'cantidad_pliego_resma',
      label: 'Cantidad de Pliego Resma',
    },
    {
      name: 'material',
      label: 'Material',
    },
    {
      name: 'gramaje',
      label: 'Gramaje',
    },
    {
      name: 'ancho_resma',
      label: 'Ancho Resma',
    },
    {
      name: 'largo_resma',
      label: 'Largo Resma',
    },
    {
      name: 'fecha_estimada_entrega',
      label: 'Fecha Estimada de Entrega',
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};
