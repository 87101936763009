import React, { Fragment, useEffect, useState } from 'react'
import {
    Grid,
    IconButton,
} from '@material-ui/core'
import { navigate } from '@reach/router'
import MUIDataTable from "mui-datatables";

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { CustomToolbar } from "./CustomToolbar";

import { Loading } from '../Loading'

import { useFetch } from '../../hooks/useFetch'
import { useDialog } from '../../hooks/useDialog'

export const MyDataTable = ({ title, source, customColumns, canDelete = true, canRegister = true }) => {

    const { loading, getData } = useFetch()
    const [dataTable, setDataTable] = useState([])
    const { confirm } = useDialog()

    const handleTableChange = (action, tableState) => {
        // setDataTable(dataTable);
    }

    const handleClick = () => {
        navigate(`${source}/register`)
    }
    
    const actions = [
        {
            name: "Acciones",
            label: 'Acciones',
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const index = tableMeta.rowIndex
                    const id = tableMeta.rowData[1]
                    return (
                        <Fragment>
                            <IconButton
                                onClick={() => {
                                    navigate(`${source}/${id}/edit`)
                                }}
                            >
                                <EditIcon color={'primary'} />
                            </IconButton>

                            {canDelete && <IconButton
                                onClick={() => {
                                    confirm({ title: 'Mensaje', message: '¿Estas seguro de querer eliminar este registro?' })
                                    .then(async (result) => {
                                        if(result) {
                                            const response = await getData({ source: `${source}/${id}`, method: 'DELETE', bg: 'true' })
                                            if(response.status === 200) {
                                                const tmp = [...dataTable];
                                                tmp.splice(index, 1);
                                                // tmp.shift();
                                                setDataTable(tmp);
                                            }
                                        }
                                    })
                                }}
                            >
                                <DeleteIcon color={'secondary'} />
                            </IconButton>}

                        </Fragment>
                    )
                }
            }
        }
    ]

    const columns = [
        ...actions,
        ...customColumns
    ];

    const options = {
        filter: true,
        print: false,
        selectableRows: 'none',
        // download: false,
        rowsPerPage: 15,
        filterType: 'dropdown',
        responsive: 'scrollFullHeight',
        onTableChange: handleTableChange,
        // onRowsDelete: (rowsDeleted) => {
        //   const dataId = new FormData()
        //   rowsDeleted.data.map(row => {
        //     dataId.append('id[]', data[row.index].id)
        //     console.log(data[row.index].id)
        //   })
        //   return false;
        //   console.log(rowsDeleted, "were deleted!");
        // },
        customToolbar: () => canRegister ? <CustomToolbar tooltip="Registrar" handleClick={handleClick} /> : <React.Fragment></React.Fragment>,
        downloadOptions: {
            filename: 'excel-format.csv',
            separator: ';',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            }
        },
    }

    useEffect(() => {
        (async function() {
            const response = await getData({ source: source, bg: true })
            if(response.status === 200) setDataTable(response.data)
        })()
    }, [getData, source])

    if(loading) {
        return (
            <Grid item xs={12}>
                <Loading />
            </Grid>
        )
    }

    return (
        <Grid item xs={12}>
            <MUIDataTable title={title} data={dataTable} columns={columns} options={options} />
        </Grid>
    )

}