import styled from "styled-components";
import Logo from '../../assets/images/inicio.png';

const azure = "#009ee2";

export const StyledDiv = styled.div`
  && .a {
    height: 100vh;
    min-height: 600px;
    background-color: white !important;
  }

  && .b {
    height: 100vh;
    min-height: 600px;
    background-image: url(${Logo});
    background-position: center center;
    background-size: cover;
  }

  && .header {
    background: black;
    height: 15vh;
    position: absolute;
    top: 0;
    width: 100%;
  }

  && .header img {
    margin-top: 2.5vh;
    height: 10vh !important;
  }

  && footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2em 0;
  }

  && form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  && .title {
    font-family: "PoppinsBold";
    color: black;
    margin-bottom: 0.5em;
    display: grid;
  }

  && .color-bar {
    height: 5px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  && .color-bar div {
    width: 15px;
    height: 100%;
    margin: 0 2px;
  }

  && .form-group {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  && .form-group .form-control {
    width: 400px;
  }

  && .form-group label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: "Poppins";
    width: 440px;
  }

  && .form-group .form-control[type="radio"] {
    height: 2em;
    width: 1em;
    margin: 0 1em 0 1.25em;
    border-color: ${azure};
    cursor: pointer;
    background: ${azure};
    color: ${azure};
  }

  && form .buttons {
    margin-bottom: 1em;
  }

  && form a,
  && form span {
    font-family: "Poppins";
  }

  && form a {
    font-weight: 600;
    font-size: 14px;
    color: ${azure};
  }

  && .image img {
    height: 100vh;
  }

  && .btn-primary {
    font-size: 14px;
    min-width: 10.65em;
    background-color: ${azure};
    font-family: "PoppinsSemiBold";
    color: white;
    padding: 0.75em 0;
    transition-property: background-color, border;
    transition-duration: 500ms;
    transition-timing-function: ease;
    border: 2px solid ${azure};
  }
  && .btn-primary:hover {
    background-color: black;
    border: 2px solid black;
  }

  && .form-control {
    border-radius: 2em;
    border-color: ${azure};
    margin: 0.75em;
    padding: 0 1.5em;
    height: 45px;
    font-family: "Poppins";
  }
`;
