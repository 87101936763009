import React, { useState, useEffect } from 'react'
import {
    Grid,
} from '@material-ui/core'

import {
    StyledSelect,
} from './styles'

export const MyAutocomplete = ({ name, label, controller, options = [], autoFocus = false, required = false, disabled }) => {

    const [value, setValue] = useState(null)

    useEffect(() => {
        const tmp = options.filter((item) => controller.value === item.value && item)
        setValue(tmp ? tmp : null)
    }, [controller.value, options])

    const handleChange = (selected) => {
        setValue(selected)
        controller.setValue(selected.value)
    }

    return (
        <Grid item xs={12} md={6}>
            <StyledSelect
                name={name}
                value={value}
                onChange={handleChange}
                placeholder={label}
                autoFocus={autoFocus}
                isDisabled={disabled}
                options={options}
                classNamePrefix ="autocomplete"
            />
        </Grid>
    )
}