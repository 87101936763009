import React, { createContext, useState } from 'react';

import { useLocalStorage } from '../hooks/useLocalStorage';

export const UserContext = createContext();

const Provider = ({ children }) => {
  const auth = useLocalStorage('token');
  const user = useLocalStorage('user');
  const [role, setRole] = useState([]);
  // const BASE_URL = 'http://localhost:3003';
  const BASE_URL = 'https://api.impalperu.com'

  const value = {
    auth,
    user,
    role,
    setRole,
    BASE_URL,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default {
  Provider,
  Consumer: UserContext.Consumer,
};
