import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Link } from '@reach/router';

import { ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { StyledList, StyledListItem, StyledListItemExpand, StyledCollapse, StyledCardMedia } from './styles';

import { MyAvatar } from '../MyAvatar';

import { UserContext } from '../../contexts/UserContext';
import { LayoutContext } from '../../contexts/LayoutContext';
import { useCheckRole } from '../../hooks/useCheckRole';

export const MyList = () => {
  const { user, BASE_URL } = useContext(UserContext);
  const { menu } = useContext(LayoutContext);
  const { can } = useCheckRole();

  const [name, setName] = useState('');
  const [expand, setExpand] = useState(menu.value);

  useEffect(() => {
    user.value && setName(user.value.name);
  }, [user.value]);

  useEffect(() => {
    menu.setItem(expand);
  }, [expand, menu]);

  return (
    <StyledList component='nav'>
      <StyledCardMedia image="/assets/images/logo.jpeg" />

      <MyListItemExpand text={name} expand={expand} setExpand={setExpand}>
        <MyAvatar
          title='Avatar'
          source={user.value && user.value.photo ? `${BASE_URL}/profiles/${user.value.photo}` : '/assets/images/profile.png'}
          size={40}
          margin={-10}
        />
        <StyledList component='div' disablePadding>
          <Link to='/perfil'>
            <MyListItem text='Perfil'>
              <SupervisedUserCircleIcon />
            </MyListItem>
          </Link>

          {can(['DASHBOARD']) && (
            <Link to='/dashboard'>
              <MyListItem text='Dashboard'>
                <DashboardIcon />
              </MyListItem>
            </Link>
          )}
        </StyledList>
      </MyListItemExpand>

      {can(['CLIENTES', 'PEDIDO', 'ORDEN DE VENTA', 'PRODUCTOS']) && (
        <MyListItemExpand text='COMERCIAL' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['CLIENTES']) && (
              <Link to='/clients'>
                <MyListItem text='Clientes'>
                  <PeopleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['PEDIDO']) && (
              <Link to='/orders'>
                <MyListItem text='Pedidos'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['ORDEN DE VENTA']) && (
              <Link to='/sale-order'>
                <MyListItem text='Orden de Venta'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
            {can(['PRODUCTOS']) && (
              <Link to='/products'>
                <MyListItem text='Productos'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['VISTO BUENO', 'USUARIOS', 'ROLES']) && (
        <MyListItemExpand text='GERENCIA' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['VISTO BUENO']) && (
              <Link to='/approval'>
                <MyListItem text='Visto Bueno'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['USUARIOS']) && (
              <Link to='/users'>
                <MyListItem text='Usuarios'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['ROLES']) && (
              <Link to='/roles'>
                <MyListItem text='Roles'>
                  <AssignmentIndIcon />
                </MyListItem>
              </Link>
            )}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['PLANEAMIENTO']) && (
        <MyListItemExpand text='PLANEAMIENTO' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/planning'>
              <MyListItem text='Planeamiento'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['PREPRENSA']) && (
        <MyListItemExpand text='PRE-PRENSA' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/prepress'>
              <MyListItem text='Pre-Prensa'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['LOGISTICA DE COMPRA', 'LOGISTICA DE VENTA']) && (
        <MyListItemExpand text='LOGISTICA' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['LOGISTICA DE COMPRA']) && (
              <Link to='/purchase-logistics'>
                <MyListItem text='Compras'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['LOGISTICA DE VENTA']) && (
              <Link to='/shipping-logistics'>
                <MyListItem text='Despachos'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['PRODUCCION']) && (
        <MyListItemExpand text='PRODUCCION' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/production'>
              <MyListItem text='Produccion'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['CONTROL DE CALIDAD']) && (
        <MyListItemExpand text='CONTROL DE CALIDAD' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/quality-control'>
              <MyListItem text='Control de Calidad'>
                <AssignmentIndIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['FACTURACION']) && (
        <MyListItemExpand text='FACTURACION' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/billing'>
              <MyListItem text='Facturacion'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}
    </StyledList>
  );
};

const MyListItem = ({ children, text }) => {
  return (
    <StyledListItem button>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={text} />
    </StyledListItem>
  );
};

const MyListItemExpand = ({ children, text, expand, setExpand }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (expand === text) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [text, expand]);

  const handleClick = value => {
    setOpen(value);
    value ? setExpand(text) : setExpand(null);
  };

  return (
    <Fragment>
      <StyledListItemExpand text='Inbox' onClick={() => handleClick(!open)}>
        <ListItemIcon>{children[0]}</ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItemExpand>
      <StyledCollapse in={open} timeout='auto' unmountOnExit>
        {children[1]}
      </StyledCollapse>
    </Fragment>
  );
};
