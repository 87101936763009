import styled from 'styled-components'

export const StyledDiv = styled.div`
    display: flex;
    flex-wrap: nowrap;
`

export const StyledContainer = styled.div`
    flex-grow: 1;
`

export const StyledMain = styled.main`
    flex-grow: 1;
    padding: 2rem;
`
