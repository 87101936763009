import styled from "styled-components";
import { List, ListItem, Collapse, CardMedia } from "@material-ui/core";

export const StyledList = styled(List)`
  width: 100%;
  color: white !important;

  && .MuiListItemIcon-root {
    color: white !important;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding-left: 25px !important;
`;

export const StyledListItemExpand = styled(ListItem)`
  padding-left: 25px !important;
`;

export const StyledCollapse = styled(Collapse)`
  /* padding-left: 25px !important; */
`;

export const StyledCardMedia = styled(CardMedia)`
  height: 100px;
`;
