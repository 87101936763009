import React, { useState, useEffect, useCallback, Fragment, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import swal from 'sweetalert';
import moment from 'moment';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';
import { UserContext } from '../../../contexts/UserContext';

export const ApprovalRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { BASE_URL } = useContext(UserContext);

  // Controladores para los campos de formulario
  const codigo = useInputValue('');
  const razonSocial = useInputValue('');
  const fechaIngreso = useInputValue('');
  const fechaSolicitadaEntrega = useInputValue('');
  const vistoBueno = useInputValue('');
  const [trabajos, setTrabajos] = useState([]);
  const [preview, setPreview] = useState(null);

  // Funciones y Callbacks

  const setDataF = (pedido, trabajosResponse) => {
      codigo.setValue(pedido.codigo);
      razonSocial.setValue(pedido.razon_social);
      fechaIngreso.setValue(moment(pedido.fecha_ingreso).format('DD-MM-YYYY'));
      fechaSolicitadaEntrega.setValue(moment(pedido.fecha_solicitada_entrega).format('DD-MM-YYYY'));
      vistoBueno.setValue(pedido.visto_bueno || '');
      setTrabajos(trabajosResponse);
      setPreview(`${BASE_URL}/documents/${pedido.documento}`);
    },
    setData = useCallback(setDataF, []);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      if (id) {
        const [pedido, trabajosResponse] = await Promise.all([
          getData({ source: `/orders/${id}` }),
          getData({ source: `/orders/${id}/jobs` }),
        ]);
        pedido.status === 200 && trabajosResponse.status === 200 && setData(pedido.data, trabajosResponse.data);
      }
    })();
  }, [id, getData, setData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    if (!vistoBueno.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const formData = new FormData(event.target);
    setError(undefined);
    const response = await getData({ source: `/approval/${id}`, body: formData, method: 'PUT' });
    if (response.status === 200) {
      await swal('Informacion actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyInput name='codigo' label='Codigo' controller={codigo} disabled={true} />

          <MyInput name='razonSocial' label='Cliente' controller={razonSocial} disabled={true} />

          <MyInput name='fechaIngreso' label='Fecha de Ingreso' controller={fechaIngreso} disabled={true} />

          <MyInput name='fechaSolicitadaEntrega' label='Fecha Solicitada de Entrega' controller={fechaSolicitadaEntrega} disabled={true} />

          {preview && (
            <Grid item xs={12}>
              <object width='100%' height='500' data={preview}>
                <p>Error al mostrar el documento.</p>
              </object>
            </Grid>
          )}

          <Grid item xs={12}>
            <DataTable title='Trabajos' data={trabajos} setData={setTrabajos} />
          </Grid>

          <MySelect
            name='vistoBueno'
            label='Visto Bueno'
            required={true}
            controller={vistoBueno}
            disabled={loading}
            data={[
              { id: 0, name: 'RECHAZADO' },
              { id: 1, name: 'APROBADO' },
            ]}
          />

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data, setData }) => {
  const columns = [
    {
      name: 'numero_cotizacion',
      label: '#Cotizacion',
    },
    {
      name: 'numero_orden_compra',
      label: '#Orden de Compra',
    },
    {
      name: 'numero_orden_venta',
      label: '#Orden de Venta',
    },
    {
      name: 'descripcion',
      label: 'Descripcion',
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};
