import { useState } from "react";

export const useInputValue = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  const onChange = (event, toCase) => {
    // setValue(toCase === undefined ? event.target.value : toCase === 'upper' ? event.target.value.toUpperCase() : event.target.value.toLowerCase())
    setValue(event.target.value);
    setError("");
  };

  return { value, setValue, onChange, error, setError };
};
