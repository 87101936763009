import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography, FormControlLabel, TextField } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import swal from 'sweetalert';
/* COMPONENTES */
import { CustomToolbar } from '../../../components/MyDataTable/CustomToolbar';
import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyDatePicker } from '../../../components/MyDatePicker';
import { MyMultiSelect } from '../../../components/MyMultiSelect';
import { MyTransferList } from '../../../components/TransferList';
import { DividerTitle } from '../../../components/DividerTitle';
import { MyAutocomplete } from '../../../components/MyAutocomplete';
/* HOOKS */
import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

export const PlanningRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const descripcion = useInputValue('');
  const codigoProducto = useInputValue('');
  const numeroOrdenProduccion = useInputValue('');
  const tipoTrabajo = useInputValue('');
  const cantidad = useInputValue('');
  const concavidadPanou = useInputValue('');
  const merma = useInputValue('');
  const cantidadImpresiones = useInputValue('');
  const material = useInputValue('');
  const gramaje = useInputValue('');
  const anchoResma = useInputValue('');
  const largoResma = useInputValue('');
  const anchoMaquina = useInputValue('');
  const largoMaquina = useInputValue('');
  const anchoProducto = useInputValue('');
  const largoProducto = useInputValue('');
  const lados = useInputValue('');
  const cantidadPliegoResma = useInputValue('');
  const fechaEstimadaEntrega = useInputValue(null);
  const observaciones = useInputValue('');

  // Data para los selects
  const [tipoTrabajoOption, setTipoTrabajoOption] = useState([]);
  const [materialOption, setMaterialOption] = useState([]);
  const [gramajesOption, setGramajesOption] = useState([]);
  const [colores, setColores] = useState([]);
  const [pantones, setPantones] = useState([]);
  const [procesos, setProcesos] = useState([]);
  // const [listaMermas, setListaMermas] = useState([])
  const [listaProcesos, setListaProcesos] = useState([]);
  const [listaColores, setListaColores] = useState([]);
  const [listaCodigoProducto, setListaCodigoProducto] = useState([]);

  // Funciones y Callbacks
  const setDataF = trabajos => {
      descripcion.setValue(trabajos.descripcion);
      codigoProducto.setValue(parseInt(trabajos.codigo_producto));
      numeroOrdenProduccion.setValue(trabajos.numero_orden_produccion);
      tipoTrabajo.setValue(trabajos.tipo_trabajo || '');
      cantidad.setValue(trabajos.cantidad || '');
      concavidadPanou.setValue(trabajos.concavidad_panou || '');
      merma.setValue(trabajos.merma || '');
      cantidadImpresiones.setValue(trabajos.cantidad_impresiones || '');
      material.setValue(trabajos.material || '');
      gramaje.setValue(trabajos.gramaje || '');
      anchoResma.setValue(trabajos.ancho_resma || '');
      largoResma.setValue(trabajos.largo_resma || '');
      anchoMaquina.setValue(trabajos.ancho_maquina || '');
      largoMaquina.setValue(trabajos.largo_maquina || '');
      anchoProducto.setValue(trabajos.ancho_producto || '');
      largoProducto.setValue(trabajos.largo_producto || '');
      lados.setValue(trabajos.lados || '');
      cantidadPliegoResma.setValue(trabajos.cantidad_pliego_resma || '');
      fechaEstimadaEntrega.setValue(trabajos.fecha_estimada_entrega);
      observaciones.setValue(trabajos.observaciones_planeamiento || '');
    },
    setData = useCallback(setDataF, []);

  //   useEffect(() => {
  //     if (cantidad.value && concavidadPanou.value) {
  //       const pliegos = cantidad.value / concavidadPanou.value;
  //       for (const item of listaMermas) {
  //         if (!(pliegos > item.cantidad)) {
  //           if (pliegos > 20000) {
  //             merma.setValue((item.merma / 100) * pliegos);
  //             cantidadImpresiones.setValue(parseInt(pliegos) + (item.merma / 100) * pliegos);
  //           } else {
  //             merma.setValue(item.merma);
  //             cantidadImpresiones.setValue(parseInt(pliegos) + parseInt(item.merma));
  //           }
  //           break;
  //         }
  //       }
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [cantidad.value, concavidadPanou.value, listaMermas]);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');
    (async function() {
      if (id) {
        const [
          trabajos,
          tiposTrabajos,
          materiales,
          // mermas,
          procesosRes,
          listaProcesosRes,
          coloresRes,
          listaColores,
          pantonesRes,
          _codigoProducto,
        ] = await Promise.all([
          getData({ source: `/jobs/${id}/edit` }),
          getData({ source: `/jobs/types` }),
          getData({ source: `/jobs/materials` }),
          // getData({ source: `/jobs/mermas` }),
          getData({ source: `/jobs/procesos/${id}` }),
          getData({ source: `/jobs/lista-procesos` }),
          getData({ source: `/jobs/${id}/colores` }),
          getData({ source: `/jobs/lista-colores` }),
          getData({ source: `/jobs/${id}/pantones` }),
          getData({ source: `/products/${id}/filter-client` }),
        ]);
        trabajos.status === 200 && setData(trabajos.data);
        tiposTrabajos.status === 200 && setTipoTrabajoOption(tiposTrabajos.data);
        materiales.status === 200 && setMaterialOption(materiales.data);
        // mermas.status === 200 && setListaMermas(mermas.data)
        procesosRes.status === 200 && setProcesos(procesosRes.data);
        listaProcesosRes.status === 200 && setListaProcesos(listaProcesosRes.data);
        coloresRes.status === 200 && setColores(coloresRes.data);
        listaColores.status === 200 && setListaColores(listaColores.data);
        pantonesRes.status === 200 && setPantones(pantonesRes.data);
        _codigoProducto.status === 200 && setListaCodigoProducto(_codigoProducto.data);
      }
    })();
  }, [id, getData, setData]);

  useEffect(() => {
    if (material.value) {
      (async function() {
        const response = await getData({
          source: `/jobs/gramajes/${material.value}`,
        });
        response.status === 200 && setGramajesOption(response.data);
      })();
    }
  }, [material.value, getData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    // if(
    //     !tipoTrabajo.value
    //     || !cantidad.value
    //     || !concavidadPanou.value
    //     || !descripcion.value
    //     || !material.value
    //     || !gramaje.value
    //     || !ancho.value
    //     || !largo.value
    //     || !lados.value
    //     || !fechaEstimadaEntrega.value
    // ) {
    //     swal('Debe llenar los campos requeridos.', { icon: "error" })
    //     return;
    // }

    const body = {
      codigoProducto: codigoProducto.value,
      tipoTrabajo: tipoTrabajo.value,
      cantidad: cantidad.value,
      concavidadPanou: concavidadPanou.value,
      merma: merma.value,
      cantidadImpresiones: cantidadImpresiones.value,
      material: material.value,
      gramaje: gramaje.value,
      anchoResma: anchoResma.value,
      largoResma: largoResma.value,
      anchoMaquina: anchoMaquina.value,
      largoMaquina: largoMaquina.value,
      anchoProducto: anchoProducto.value,
      largoProducto: largoProducto.value,
      lados: lados.value,
      cantidadPliegoResma: cantidadPliegoResma.value,
      colores: colores,
      pantones: pantones,
      procesos: procesos,
      fechaEstimadaEntrega: fechaEstimadaEntrega.value,
      observaciones: observaciones.value,
    };
    setError(undefined);
    const response = await getData({
      source: `/planning/${id}`,
      body: body,
      json: true,
      method: 'PUT',
    });
    if (response.status === 200) {
      await swal('Informacion actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyInput name='descripcion' label='Descripcion' controller={descripcion} disabled={true} />
          {/* <MyInput name='codigoProducto' label='Codigo de Producto' controller={codigoProducto} disabled={true} /> */}
          <MyAutocomplete
            name='codigoProducto'
            label='Codigo de Producto'
            options={listaCodigoProducto}
            controller={codigoProducto}
            disabled={loading}
          />
          <MyInput name='numeroOrdenProduccion' label='#Orden de Produccion' controller={numeroOrdenProduccion} disabled={true} />
          <MySelect
            name='tipoTrabajo'
            label='Tipo de Trabajo'
            required={true}
            controller={tipoTrabajo}
            disabled={loading}
            data={tipoTrabajoOption}
          />
          <MyInput name='cantidad' label='Cantidad de Pedido' required={true} controller={cantidad} type='number' disabled={loading} />
          <MyInput
            name='concavidadPanou'
            label='Concavidad Panou'
            required={true}
            controller={concavidadPanou}
            type='number'
            disabled={loading}
          />
          <MyInput name='merma' label='Demasía' controller={merma} type='number' disabled={loading} />
          <MyInput name='cantidadImpresiones' label='Pliego Máquina' controller={cantidadImpresiones} type='number' disabled={loading} />
          <MySelect name='material' label='Material' required={true} controller={material} disabled={loading} data={materialOption} />
          <MySelect name='gramaje' label='Gramaje' required={true} controller={gramaje} disabled={loading} data={gramajesOption} />
          <MyInput
            name='cantidadPliegoResma'
            label='Cantidad de Pliego Resma'
            required={true}
            controller={cantidadPliegoResma}
            type='number'
            disabled={loading}
          />
          <MySelect
            name='lados'
            label='Lados'
            required={true}
            controller={lados}
            disabled={loading}
            data={[
              { id: 1, name: 'T' },
              { id: 2, name: 'T/R' },
            ]}
          />
          <DividerTitle title='Medidas de Pliego Resma' />
          <MyInput
            name='ancho_resma'
            label='Ancho'
            required={true}
            controller={anchoResma}
            type='number'
            disabled={loading}
            helperText='SENTIDO DE LA FIBRA'
          />
          <MyInput name='largo_resma' label='Largo' required={true} controller={largoResma} type='number' disabled={loading} />
          <DividerTitle title='Medidas de Pliego Máquina' />
          <MyInput
            name='ancho_maquina'
            label='Ancho'
            required={true}
            controller={anchoMaquina}
            type='number'
            disabled={loading}
            helperText='SENTIDO DE LA FIBRA'
          />
          <MyInput name='largo_maquina' label='Largo' required={true} controller={largoMaquina} type='number' disabled={loading} />
          <DividerTitle title='Medidas de Producto' />
          <MyInput
            name='ancho_producto'
            label='Ancho'
            required={true}
            controller={anchoProducto}
            type='number'
            disabled={loading}
            helperText='SENTIDO DE LA FIBRA'
          />
          <MyInput name='largo_producto' label='Largo' required={true} controller={largoProducto} type='number' disabled={loading} />
          <MyMultiSelect data={colores} setData={setColores} label='Colores' options={listaColores} />
          <Grid item xs={12}>
            <DataTable title='Pantones' data={pantones} setData={setPantones} />
          </Grid>
          <MyTransferList data={procesos} setData={setProcesos} options={listaProcesos} />
          <MyDatePicker
            name='fechaEstimadaEntrega'
            label='Fecha Estimada de Entrega'
            required={true}
            controller={fechaEstimadaEntrega}
            disabled={loading}
          />
          <MyInput
            name='observaciones'
            label='Observaciones'
            toCase='upper'
            controller={observaciones}
            rows={4}
            md={12}
            disabled={loading}
          />
          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data, setData }) => {
  const handleClick = () => {
    setData([...data, { id: '', name: '' }]);
  };

  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const deleteRow = rowsDeleted => {
    const tmp = data;
    const sorted = rowsDeleted.sort((a, b) => a.index - b.index);
    sorted.reverse().forEach(item => tmp.splice(item.index, 1));
    setData(tmp);
  };

  const columns = [
    {
      name: 'name',
      label: 'Panton',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'name', event.target.value);
            }}
          />
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    customToolbar: () => <CustomToolbar tooltip='Agregar acabado' handleClick={handleClick} />,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
    onRowsDelete: rowsDeleted => {
      deleteRow(rowsDeleted.data);
    },
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};
