import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

import { StyledAppBar, StyledToolbar, StyledTypography } from './styles';
import { MyMenu } from '../MyMenu';
import { UserContext } from '../../contexts/UserContext';

export const MyAppBar = ({ openDrawer, setOpenDrawer }) => {
  const { auth, user, setRole } = useContext(UserContext);

  const handleSignOut = () => {
    user.removeItem();
    setRole([]);
    auth.removeItem();
  };

  return (
    <StyledAppBar position='static'>
      <StyledToolbar>
        <IconButton
          edge='start'
          aria-label='menu'
          className='menu'
          onClick={() => {
            setOpenDrawer(!openDrawer);
          }}
        >
          <MenuIcon />
        </IconButton>
        <StyledTypography>IMPAL SAP</StyledTypography>
        <MyMenu handleSignOut={handleSignOut}>
          <PersonIcon />
          {user.value && user.value.name}
        </MyMenu>
      </StyledToolbar>
    </StyledAppBar>
  );
};
