import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography, FormControlLabel, TextField, Select } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import swal from 'sweetalert';
import moment from 'moment';

import { CustomToolbar } from '../../../components/MyDataTable/CustomToolbar';
import { MyPaper } from '../../../components/MyPaper';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import { useInputValue } from '../../../hooks/useInputValue';
import { MyInput } from '../../../components/MyInput';

import { useFetch } from '../../../hooks/useFetch';

export const ShippingLogisticsRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  // Controladores para los campos de formulario
  const [trabajos, setTrabajos] = useState([]);
  const [logistica, setLogistica] = useState([]);
  const observaciones = useInputValue('');

  // Funciones y Callbacks
  const setDataF = (trabajo, logistica) => {
      setTrabajos([trabajo]);
      setLogistica(logistica);
      observaciones.setValue(trabajo.observaciones_envios || '');
    },
    setData = useCallback(setDataF, []);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');
    (async function() {
      if (id) {
        const [trabajo, logistica] = await Promise.all([
          getData({ source: `/purchase-logistics/${id}` }),
          getData({ source: `/shipping-logistics/${id}` }),
        ]);
        trabajo.status === 200 && logistica.status === 200 && setData(trabajo.data, logistica.data);
      }
    })();
  }, [id, getData, setData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!logistica.length) {
      swal('Debe ingresar al menos 1 guía.', { icon: 'error' });
      return;
    }
    const body = {
      logistica: logistica,
      observaciones: observaciones.value,
    };
    setError(undefined);
    const response = await getData({ source: `/shipping-logistics/${id}`, body: body, json: true, method: 'POST' });
    if (response.status === 200) {
      await swal('Informacion actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <DataTable title='Trabajo' data={trabajos} />
          </Grid>
          <MyInput
            name='observaciones'
            label='Observaciones'
            toCase='upper'
            controller={observaciones}
            rows={4}
            md={12}
            disabled={loading}
          />
          <Grid item xs={12}>
            <LogisticaDataTable title='Logistica de envio' data={logistica} setData={setLogistica} />
          </Grid>
          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data, setData }) => {
  const columns = [
    {
      name: 'numero_orden_produccion',
      label: '#Orden de Produccion',
    },
    {
      name: 'tipo_trabajo',
      label: 'Tipo de Trabajo',
    },
    {
      name: 'cantidad_impresiones',
      label: 'Cantidad de Producto',
    },
    {
      name: 'material',
      label: 'Material',
    },
    {
      name: 'gramaje',
      label: 'Gramaje',
    },
    {
      name: 'ancho_producto',
      label: 'Ancho Producto',
    },
    {
      name: 'largo_producto',
      label: 'Largo Producto',
    },
    {
      name: 'fecha_estimada_entrega',
      label: 'Fecha Estimada de Entrega',
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};

const LogisticaDataTable = ({ title, data, setData }) => {
  const handleClick = () => {
    setData([...data, { fecha: null, guia_remision: '', cantidad: '', peso: '', estado: '' }]);
  };

  const updateData = (row, column, value) => {
    const tmp = data;
    tmp[row][column] = value;
    setData(tmp);
  };

  const deleteRow = rowsDeleted => {
    const tmp = data;
    const sorted = rowsDeleted.sort((a, b) => a.index - b.index);
    sorted.reverse().forEach(item => tmp.splice(item.index, 1));
    setData(tmp);
  };

  const columns = [
    {
      name: 'fecha',
      label: 'Fecha de Envio',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SimpleDatePicker
              initial={value}
              onChange={date => {
                updateValue(date);
                updateData(tableMeta.rowIndex, 'fecha', date);
              }}
            />
          );
        },
      },
    },
    {
      name: 'guia_remision',
      label: '#Guia de Remision',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              control={<TextField value={value || ''} />}
              onChange={event => {
                updateValue(event.target.value);
                updateData(tableMeta.rowIndex, 'guia_remision', event.target.value);
              }}
            />
          );
        },
      },
    },
    {
      name: 'cantidad',
      label: 'Cantidad Despachada',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              control={<TextField type='number' value={value || ''} />}
              onChange={event => {
                updateValue(event.target.value);
                updateData(tableMeta.rowIndex, 'cantidad', event.target.value);
              }}
            />
          );
        },
      },
    },
    {
      name: 'peso',
      label: 'Peso de Salida',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={<TextField type='number' value={value || ''} />}
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'peso', event.target.value);
            }}
          />
        ),
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={
              <Select value={value || ''} native>
                <option value=''>SELECCIONE</option>
                <option value='PENDIENTE'>PENDIENTE</option>
                <option value='DESPACHO PARCIAL'>DESPACHO PARCIAL</option>
                <option value='DESPACHO FINAL'>DESPACHO FINAL</option>
              </Select>
            }
            onChange={event => {
              updateValue(event.target.value);
              updateData(tableMeta.rowIndex, 'estado', event.target.value);
            }}
          />
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    customToolbar: () => <CustomToolbar tooltip='Agregar trabajo' handleClick={handleClick} />,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
    onRowsDelete: rowsDeleted => {
      deleteRow(rowsDeleted.data);
    },
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};

const SimpleDatePicker = ({ initial, onChange }) => {
  console.log(initial)
  const [date, setDate] = useState(initial ? moment(initial) : null);
  const handleChange = value => {
    onChange(value);
    setDate(value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DatePicker value={date || null} onChange={handleChange} format='dd-MM-yyyy' disableToolbar margin='normal' fullWidth />
    </MuiPickersUtilsProvider>
  );
};
