import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const ShippingLogisticsMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'descripcion',
      label: 'Descripcion',
      options: {
        filter: true,
      },
    },
    {
      name: 'codigo_producto',
      label: 'Codigo de Producto',
      options: {
        filter: true,
      },
    },
    {
      name: 'numero_orden_produccion',
      label: '#Orden de Produccion',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_estimada_entrega',
      label: 'Fecha Estimada de Entrega',
      options: {
        filter: true,
      },
    },
    {
      name: 'logistica',
      label: 'Logistica',
      options: {
        filter: true,
      },
    },
  ];
  return (
    <MyDataTable
      title='Logistica de Envio'
      source='/shipping-logistics'
      customColumns={customColumns}
      canDelete={false}
      canRegister={false}
    />
  );
};
