import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const SaleOrderMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: '#Orden de Venta',
      options: {
        filter: false,
      },
    },
    {
      name: 'fecha',
      label: 'Fecha',
      options: {
        filter: true,
      },
    },
    {
      name: 'razon_social',
      label: 'Cliente',
      options: {
        filter: true,
      },
    },
    {
      name: 'ruc',
      label: 'RUC',
      options: {
        filter: true,
      },
    },
    {
      name: 'codigo',
      label: 'Codigo',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Orden de Venta' source='/sale-order' customColumns={customColumns} canDelete={false} />;
};
