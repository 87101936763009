import React, { Fragment } from 'react'

export const SaleOrder = ({children}) => {

    return (

        <Fragment>

            {children}

        </Fragment>

    )

}