import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

export const StyledAvatar = styled(Avatar)`
  margin: 20px 0;
  width: 200px !important;
  height: 200px !important;
`;

export const File = styled.input`
  width: 0px;
  height: 0px;
`;