import styled from 'styled-components'
import {
    Drawer
} from '@material-ui/core'

export const StyledDrawer = styled(Drawer)`
    width: ${props => props.width}px;
    flex-shrink: 0 !important;
    white-space: nowrap !important;
    z-index: 101 !important;
    transition: .25s ease;
    /*
    background-image: url('/assets/images/bg-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    */
    min-height: 100vh;
    && .MuiPaper-root {
        background: rgba(0,0,0,1);
        transition: .25s ease;
        width: ${props => props.width}px;
        overflow-x: hidden;
        /* background: #424242; */
    }
`