import React, { useState, useEffect, useCallback, Fragment } from 'react'
import {
    Grid,
    Box,
    Button,
    CircularProgress,
    Typography,
} from '@material-ui/core'
import swal from 'sweetalert'

import { MyPaper } from '../../../components/MyPaper'
import { MyInput } from '../../../components/MyInput'
import { MyTransferList } from '../../../components/MyTransferList'

import { useInputValue } from '../../../hooks/useInputValue'
import { useFetch } from '../../../hooks/useFetch'

const useInitial = (id, getData) => {
    const [title, setTitle] = useState('Registrar rol')
    const [role, setRole] = useState(undefined)
    const [can, setCan] = useState([])
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        id && setTitle('Editar rol');

        (async function() {
            const [_permissions, _role] = await Promise.all([
                getData({ source: '/roles/permissions', bg: true }),
                id && getData({ source: `/roles/${id}/show` }),
            ])
            _permissions.status === 200 && setPermissions(_permissions.data)
            if(id && _role.status === 200) {
                setRole(_role.data.role)
                setCan(_role.data.can)
            }
        })()
    }, [id, getData])

    return {title, role, permissions, can, setCan}
}

export const RoleRegister = ({id}) => {

    const [error, setError] = useState(undefined)
    const {loading, getData} = useFetch()
    const {title, role, permissions, can, setCan} = useInitial(id, getData)

    // Controladores para los campos de formulario
    const name = useInputValue('')

    // Funciones y Callbacks
    const setDataF = (a) => {
        name.setValue(a.name)
    }, setData = useCallback(setDataF, [])

    useEffect(() => {
        role && setData(role)
    }, [role, setData])

    // Manejador de envio del formulario
    const handleSubmit = async (event) => {
        event.preventDefault()
        setError(undefined)

        if(
            !name.value
            || !can.length 
        ) {
            swal('Debe llenar los campos requeridos.', { icon: "error" })
            return;
        }

        const body = {
            name: name.value,
            permissions: can,
        }
        
        let message = undefined
        let response = null
        if(id) {
            message = 'Rol actualizado con exito.'
            response = await getData({ source: `/roles/${id}`, body: body, json: true, method: 'PUT' })
        } else {
            message = 'Rol registrado con exito.'
            response = await getData({ source: '/roles/', body: body, json: true, method: 'POST' })
        }
        if(response.status === 200) {
            await swal(message, { icon: "success" })
            window.history.back()
        } else {
            setError(response.message)
        }

    }

    return (

        <MyPaper title={title}>

            <form
                noValidate
                onSubmit={handleSubmit}
            >

                <Grid container item spacing={2}>

                    <Grid item xs={12}>
                        <MyInput
                            name="name"
                            label="Nombre"
                            toCase="upper"
                            controller={name}
                            autoFocus={true}
                            required={true}
                            disabled={loading}
                        />
                    </Grid>

                    <MyTransferList data={permissions} register={can} setRegister={setCan} />

                    <Grid container item justify="flex-end" alignItems="center" spacing={2}>

                        <Grid container item justify="flex-start" alignItems="center" xs={12} md={6}>
                            {loading &&
                                <Fragment>
                                    <CircularProgress />
                                    <Box ml={2}>
                                        <Typography variant="h6" color="textSecondary">Procesando...</Typography>
                                    </Box>
                                </Fragment>
                            }
                            {error && <Typography variant="h6" color="error">{error}</Typography>}
                        </Grid>

                        <Grid item xs={6} md={3}>
                            
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                onClick={() => window.history.back()}
                            >
                                Atras
                            </Button>

                        </Grid>

                        <Grid item xs={6} md={3}>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                            >
                                Guardar
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>

            </form>

        </MyPaper>

    )

}