import { createGlobalStyle } from 'styled-components'

import PoppinsBold from './assets/fonts/Poppins/Poppins-Bold.ttf';
import PoppinsSemiBold from './assets/fonts/Poppins/Poppins-SemiBold.ttf';
import PoppinsMedium from './assets/fonts/Poppins/Poppins-Medium.ttf';
import PoppinsLight from './assets/fonts/Poppins/Poppins-Light.ttf';
import PoppinsExtraLight from './assets/fonts/Poppins/Poppins-ExtraLight.ttf';
import PoppinsRegular from './assets/fonts/Poppins/Poppins-Regular.ttf';

export const GlobalStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

    html {
        font-size: 14px !important;
        font-family: 'Roboto', sans-serif;
    }

    body {
        background-color: rgba(0,0,0,0.05) !important;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    @font-face {
        font-family: 'PoppinsBold';
        src: url(${PoppinsBold}) format("truetype");
    }
    
    @font-face {
        font-family: 'PoppinsSemiBold';
        src: url(${PoppinsSemiBold}) format("truetype");
    }
    
    @font-face {
        font-family: 'PoppinsMedium';
        src: url(${PoppinsMedium}) format("truetype");
    }
    
    @font-face {
        font-family: 'PoppinsLight';
        src: url(${PoppinsLight}) format("truetype");
    }
    
    @font-face {
        font-family: 'PoppinsExtraLight';
        src: url(${PoppinsExtraLight}) format("truetype");
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url(${PoppinsRegular}) format("truetype");
    }

    @media only screen and (max-width: 767px) {
        .title{
            text-align: center;
        }
    }
    
    @media only screen and (max-width: 475px) {
        form {
            padding: 3em 2em!important;
        }
        form label {
            width: 100%!important;
        }
        .title .text{
            font-size: 28px;
            text-align: center;	
        }
        footer span { font-size: small; }
    }
`