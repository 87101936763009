import React from 'react'
import {
    Grid,
} from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from "date-fns/locale/es";

export const MyDatePicker = ({ name, label, required = false, controller, disabled }) => {

    const handleChange = (date) =>  controller.setValue(date)

    return (
        <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                    name={name}
                    id={name}
                    label={label}
                    value={controller.value}
                    onChange={handleChange}
                    error={controller.error !== ''}
                    helperText={controller.error}
                    required={required}
                    disabled={disabled}
                    format="dd/MM/yyyy"
                    disableToolbar
                    inputVariant="outlined"
                    margin="normal"
                    fullWidth
                />
            </MuiPickersUtilsProvider>
        </Grid>
    )

}