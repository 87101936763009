import React, { Fragment } from 'react'

export const Orders = ({children}) => {

    return (

        <Fragment>

            {children}

        </Fragment>

    )

}