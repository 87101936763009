import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Grid, Paper, Box } from '@material-ui/core';

import { useFetch } from '../../hooks/useFetch';
import { ProductionOrder } from './ProductionOrder';

export const Dashboard = () => {
  const { getData } = useFetch();

  useEffect(() => {
    (async () => {
      const response = await getData({ source: `/dashboard/jobs` });
      if (response.status === 200) {
        let tmp = { ...series[0] };
        tmp.data = [response.data.progreso, response.data.pendiente, response.data.total];
        setSeries([tmp]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: ['OP en Progreso', 'OP Pendientes', 'OP Total'],
    },
  };
  const [series, setSeries] = useState([
    {
      name: 'Cantidad',
      data: [0, 0, 0],
    },
  ]);

  return (
    <Grid item xs={12}>
      <Paper>
        <div className='app'>
          <div className='row'>
            <div className='mixed-chart'>
              <Chart options={options} series={series} type='bar' width='100%' height='500' />
            </div>
          </div>
        </div>
      </Paper>

      <Box mt={4}>
        <ProductionOrder />
      </Box>
    </Grid>
  );
};
