import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';
import { useDialog } from '../../../hooks/useDialog';
import { MyDivider } from '../../../components/MyDivider';

export const ClientRegister = ({ id }) => {
  const [title, setTitle] = useState('Registrar');
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { success } = useDialog();

  // Controladores para los campos de formulario
  const codigo = useInputValue('');
  const razonSocial = useInputValue('');
  const ruc = useInputValue('');
  const direccionFiscal = useInputValue('');
  const pais = useInputValue('');
  const departamento = useInputValue('');
  const provincia = useInputValue('');
  const distrito = useInputValue('');
  const direccionEntrega = useInputValue('');
  const telefonoPrincipal = useInputValue('');
  const telefonoSecundario = useInputValue('');
  const web = useInputValue('');
  const contactoComercial = useInputValue('');
  const telefonoComercial = useInputValue('');
  const correoComercial = useInputValue('');
  const contactoGR = useInputValue('');
  const telefonoGR = useInputValue('');
  const correoGR = useInputValue('');
  const contactoFactura = useInputValue('');
  const telefonoFactura = useInputValue('');
  const correoFactura = useInputValue('');
  const password = useInputValue('');

  // Data para los selects
  const [departamentoOption, setDepartamentoOption] = React.useState([]);
  const [provinciaOption, setProvinciaOption] = React.useState([]);
  const [distritoOption, setDistritoOption] = React.useState([]);

  // Funciones y Callbacks
  const setDataF = async data => {
      codigo.setValue(data.codigo);
      razonSocial.setValue(data.razon_social);
      ruc.setValue(data.ruc);
      direccionFiscal.setValue(data.direccion_fiscal);
      pais.setValue(data.pais);
      departamento.setValue(data.departamento);
      provincia.setValue(data.provincia);
      distrito.setValue(data.distrito);
      direccionEntrega.setValue(data.direccion_entrega);
      telefonoPrincipal.setValue(data.telefono_principal);
      telefonoSecundario.setValue(data.telefono_secundario);
      web.setValue(data.web);
      contactoComercial.setValue(data.contacto_comercial);
      telefonoComercial.setValue(data.telefono_comercial);
      correoComercial.setValue(data.correo_comercial);
      contactoGR.setValue(data.contacto_gr);
      telefonoGR.setValue(data.telefono_gr);
      correoGR.setValue(data.correo_gr);
      contactoFactura.setValue(data.contacto_factura);
      telefonoFactura.setValue(data.telefono_factura);
      correoFactura.setValue(data.correo_factura);

      const [provinciaResponse, distritoResponse] = await Promise.all([
        getData({ source: `/ubigeo/provincia/${data.departamento}`, bg: true }),
        getData({ source: `/ubigeo/distrito/${data.provincia}`, bg: true }),
      ]);
      provinciaResponse.status === 200 && setProvinciaOption(provinciaResponse.data);
      distritoResponse.status === 200 && setDistritoOption(distritoResponse.data);
    },
    setData = useCallback(setDataF, []);

  // Inicializando
  useEffect(() => {
    id && setTitle('Editar');

    (async function() {
      const [departamentoResponse, clienteResponse] = await Promise.all([
        getData({ source: '/ubigeo/departamento', bg: true }),
        id && getData({ source: `/clients/${id}` }),
      ]);

      departamentoResponse.status === 200 && setDepartamentoOption(departamentoResponse.data);
      id && clienteResponse.status === 200 && setData(clienteResponse.data);
    })();
  }, [id, getData, setData]);

  // Manejadores de cambio de los selects
  const handleDepartamento = async event => {
    departamento.onChange(event);
    setDistritoOption([]);
    if (event.target.value) {
      const response = await getData({ source: `/ubigeo/provincia/${event.target.value}`, bg: true });
      response.status === 200 && setProvinciaOption(response.data);
    } else setProvinciaOption([]);
  };

  const handleProvincia = async event => {
    provincia.onChange(event);
    setDistritoOption([]);
    if (event.target.value) {
      const response = await getData({ source: `/ubigeo/distrito/${event.target.value}`, bg: true });
      response.status === 200 && setDistritoOption(response.data);
    }
  };

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    let validation = 0;

    if (!codigo.value) {
      codigo.setError('Campo requerido');
      validation++;
    }
    if (!razonSocial.value) {
      razonSocial.setError('Campo requerido');
      validation++;
    }

    if (!validation) {
      const formData = new FormData(event.target);
      setError(undefined);
      let message = undefined;
      let response = null;
      if (id) {
        message = 'Usuario actualizado con exito.';
        response = await getData({ source: `/clients/${id}`, body: formData, method: 'PUT' });
      } else {
        message = 'Usuario registrado con exito.';
        response = await getData({ source: '/clients/store', body: formData, method: 'POST' });
      }
      if (response.status === 200) {
        success(message)
          .then()
          .then(() => window.history.back());
      } else {
        setError(response.message);
      }
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <MyInput name='codigo' label='Codigo' toCase='upper' required={true} autoFocus={true} controller={codigo} disabled={loading} />

          <MyInput name='razonSocial' label='Razon Social' toCase='upper' required={true} controller={razonSocial} disabled={loading} />

          <MyInput name='ruc' label='RUC/DNI' type='number' controller={ruc} disabled={loading} />

          <MyInput name='direccionFiscal' label='Direccion Fiscal' toCase='upper' controller={direccionFiscal} disabled={loading} />

          <MySelect name='pais' label='Pais' controller={pais} disabled={loading} data={[{ id: 1, name: 'PERÚ' }]} />

          <MySelect
            name='departamento'
            label='Departamento'
            controller={departamento}
            handleChange={handleDepartamento}
            disabled={loading}
            data={departamentoOption}
          />

          <MySelect
            name='provincia'
            label='Provincia'
            controller={provincia}
            handleChange={handleProvincia}
            disabled={loading}
            data={provinciaOption}
          />

          <MySelect name='distrito' label='Distrito' controller={distrito} disabled={loading} data={distritoOption} />

          <MyInput name='direccionEntrega' label='Direccion de Entrega' toCase='upper' controller={direccionEntrega} disabled={loading} />

          <MyInput name='telefonoPrincipal' label='Telefono 1' type='number' controller={telefonoPrincipal} disabled={loading} />

          <MyInput name='telefonoSecundario' label='Telefono 2' type='number' controller={telefonoSecundario} disabled={loading} />

          <MyInput name='web' label='WEB' toCase='lower' controller={web} disabled={loading} />

          <Grid item xs={12}>
            <MyDivider />
          </Grid>

          <MyInput
            name='contactoComercial'
            label='Contacto Comercial'
            type='text'
            toCase='upper'
            controller={contactoComercial}
            disabled={loading}
          />

          <MyInput name='telefonoComercial' label='Telefono Comercial' type='number' controller={telefonoComercial} disabled={loading} />

          <MyInput
            name='correoComercial'
            label='Correo Comercial'
            type='email'
            toCase='lower'
            controller={correoComercial}
            disabled={loading}
          />

          <Grid item xs={12}>
            <MyDivider />
          </Grid>

          <MyInput name='contactoGR' label='Contacto G.R.' type='text' toCase='upper' controller={contactoGR} disabled={loading} />

          <MyInput name='telefonoGR' label='Telefono GR' type='number' controller={telefonoGR} disabled={loading} />

          <MyInput name='correoGR' label='Correo GR' type='email' toCase='lower' controller={correoGR} disabled={loading} />

          <Grid item xs={12}>
            <MyDivider />
          </Grid>

          <MyInput
            name='contactoFactura'
            label='Contacto Factura'
            type='text'
            toCase='upper'
            controller={contactoFactura}
            disabled={loading}
          />

          <MyInput name='telefonoFactura' label='Telefono Factura' type='number' controller={telefonoFactura} disabled={loading} />

          <MyInput name='correoFactura' label='Correo Factura' type='email' toCase='lower' controller={correoFactura} disabled={loading} />

          <MyInput name='password' label='Clave' type='password' controller={password} disabled={loading} />

          <Grid item xs={12}>
            <MyDivider />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
