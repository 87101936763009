import React, { useEffect, useContext, Suspense } from 'react';
import { Router, Redirect } from '@reach/router';

import { UserContext } from './contexts/UserContext';

import { GlobalStyles } from './GlobalStyles';

import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Perfil } from './pages/Perfil';

import { Clients } from './pages/Clients';
import { ClientMain } from './pages/Clients/Main';
import { ClientRegister } from './pages/Clients/Register';

import { Users } from './pages/Users';
import { UserMain } from './pages/Users/Main';
import { UserRegister } from './pages/Users/Register';

import { Orders } from './pages/Orders';
import { OrderMain } from './pages/Orders/Main';
import { OrderRegister } from './pages/Orders/Register';

import { SaleOrder } from './pages/SaleOrder';
import { SaleOrderMain } from './pages/SaleOrder/Main';
import { SaleOrderRegister } from './pages/SaleOrder/Register';

import { Approval } from './pages/Approval';
import { ApprovalMain } from './pages/Approval/Main';
import { ApprovalRegister } from './pages/Approval/Register';

import { PrePress } from './pages/PrePress';
import { PrePressMain } from './pages/PrePress/Main';
import { PrePressRegister } from './pages/PrePress/Register';

import { Planning } from './pages/Planning';
import { PlanningMain } from './pages/Planning/Main';
import { PlanningRegister } from './pages/Planning/Register';

import { PurchaseLogistics } from './pages/PurchaseLogistics';
import { PurchaseLogisticsMain } from './pages/PurchaseLogistics/Main';
import { PurchaseLogisticsRegister } from './pages/PurchaseLogistics/Register';

import { Production } from './pages/Production';
import { ProductionMain } from './pages/Production/Main';
import { ProductionRegister } from './pages/Production/Register';

import { ShippingLogistics } from './pages/ShippingLogistics';
import { ShippingLogisticsMain } from './pages/ShippingLogistics/Main';
import { ShippingLogisticsRegister } from './pages/ShippingLogistics/Register';

import { Billing } from './pages/Billing';
import { BillingMain } from './pages/Billing/Main';
import { BillingRegister } from './pages/Billing/Register';

import { Role } from './pages/Role';
import { RoleMain } from './pages/Role/Main';
import { RoleRegister } from './pages/Role/Register';

import { QualityControl } from './pages/QualityControl';
import { QualityControlMain } from './pages/QualityControl/Main';
import { QualityControlRegister } from './pages/QualityControl/Register';

import { Product } from './pages/Product';
import { ProductMain } from './pages/Product/Main';
import { ProductRegister } from './pages/Product/Register';

import { Layout } from './components/Layout';

import { useFetch } from './hooks/useFetch';
import { useCheckRole } from './hooks/useCheckRole';

export const App = () => {
  const { getData } = useFetch();
  const { auth, user, role, setRole } = useContext(UserContext);
  const { can } = useCheckRole();

  useEffect(() => {
    if (auth.value && !role.length) {
      (async function() {
        const response = await getData({ source: `/roles/${user.value.role}/show` });
        response.status === 200 && setRole(response.data.can);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  return (
    <Suspense fallback={<div />}>
      <GlobalStyles />

      {!auth.value && (
        <Router>
          <Login path='/login' />
          <Redirect from='*' to='/login' noThrow />
        </Router>
      )}

      {auth.value && (
        <Layout>
          <Router>
            <Redirect from='/login' to='/dashboard' noThrow />
            <Redirect from='/' to='/dashboard' noThrow />

            <Perfil path='/perfil' />

            {can(['DASHBOARD']) && <Dashboard path='/dashboard' />}

            {can(['CLIENTES']) && <Clients path='clients'>
              <ClientMain path='/' />
              <ClientRegister path='register' />
              <ClientRegister path=':id/edit' />
            </Clients>}

            {can(['USUARIOS']) && <Users path='users'>
              <UserMain path='/' />
              <UserRegister path='register' />
              <UserRegister path=':id/edit' />
            </Users>}

            {can(['ROLES']) && <Role path='roles'>
              <RoleMain path='/' />
              <RoleRegister path='register' />
              <RoleRegister path=':id/edit' />
            </Role>}

            {can(['PEDIDO']) && <Orders path='orders'>
              <OrderMain path='/' />
              <OrderRegister path='register' />
              <OrderRegister path=':id/edit' />
            </Orders>}

            {can(['ORDEN DE VENTA']) && <SaleOrder path='sale-order'>
              <SaleOrderMain path='/' />
              <SaleOrderRegister path='register' />
              <SaleOrderRegister path=':id/edit' />
            </SaleOrder>}

            {can(['PRODUCTOS']) && <Product path='products'>
              <ProductMain path='/' />
              <ProductRegister path='register' />
              <ProductRegister path=':id/edit' />
            </Product>}

            {can(['VISTO BUENO']) && <Approval path='approval'>
              <ApprovalMain path='/' />
              <ApprovalRegister path=':id/edit' />
            </Approval>}

            {can(['PREPRENSA']) && <PrePress path='prepress'>
              <PrePressMain path='/' />
              <PrePressRegister path=':id/edit' />
            </PrePress>}

            {can(['PLANEAMIENTO']) && <Planning path='planning'>
              <PlanningMain path='/' />
              <PlanningRegister path=':id/edit' />
            </Planning>}

            {can(['LOGISTICA DE COMPRA']) && <PurchaseLogistics path='purchase-logistics'>
              <PurchaseLogisticsMain path='/' />
              <PurchaseLogisticsRegister path=':id/edit' />
            </PurchaseLogistics>}

            {can(['PRODUCCION']) && <Production path='production'>
              <ProductionMain path='/' />
              <ProductionRegister path=':id/edit' />
            </Production>}

            {can(['LOGISTICA DE VENTA']) && <ShippingLogistics path='shipping-logistics'>
              <ShippingLogisticsMain path='/' />
              <ShippingLogisticsRegister path=':id/edit' />
            </ShippingLogistics>}

            {can(['LOGISTICA DE VENTA']) && <QualityControl path='quality-control'>
              <QualityControlMain path='/' />
              <QualityControlRegister path=':id/edit' />
            </QualityControl>}

            {can(['FACTURACION']) && <Billing path='billing'>
              <BillingMain path='/' />
              <BillingRegister path='register' />
              <BillingRegister path=':id/edit' />
            </Billing>}
          </Router>
        </Layout>
      )}
    </Suspense>
  );
};
