import React, { useRef, useState } from 'react';
import { Grid, FormControl, InputLabel, Select } from '@material-ui/core';

export const MySelect = ({ name, label, required = false, controller, disabled, data = [], handleChange = () => {} }) => {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Grid item xs={12} md={6}>
      <FormControl required={required} disabled={disabled} variant='outlined' fullWidth margin='normal'>
        <InputLabel ref={inputLabel} htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          inputProps={{
            name: name,
            id: name,
          }}
          value={controller.value}
          onChange={event => {
            controller.onChange(event);
            handleChange(event);
          }}
          labelWidth={labelWidth}
          native
        >
          <option value='' />
          {data.map(item => (
            <option key={item.id ? item.id : item.name} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
