import styled from 'styled-components';

export const StyledFile = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  margin-top: 17px;
  position: relative;

  && label {
    position: absolute;
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0px 5px;
  }

  && input {
    width: 100%;
    padding: 15px 10px;
  }
`;
