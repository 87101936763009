import styled from 'styled-components'
import Select from 'react-select'

export const StyledSelect = styled(Select)`
    margin-top: 18px;
    && .autocomplete__control {
        padding: .5rem 0;
    }
    && .autocomplete__menu {
        z-index: 115;
    } 
`