import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { StyledAvatar, File } from './styles';

import { MyPaper } from '../../components/MyPaper';
import { MyInput } from '../../components/MyInput';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';

import { UserContext } from '../../contexts/UserContext';

export const Perfil = ({ id }) => {
  const title = 'Perfil';
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);
  const { user, BASE_URL } = useContext(UserContext);
  const inputFile = document.querySelector('#foto');

  const uuid = useInputValue('');
  const name = useInputValue('');
  const email = useInputValue('');
  const password = useInputValue('');
  const phone = useInputValue('');
  const [foto, setFoto] = useState(null);

  const handleFileChange = async e => {
    if (e.target.files[0]) {
      const blob = await fileToBlob(e.target.files[0]);
      setFoto(blob);
    }
  };

  const fileToBlob = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  useEffect(() => {
    if (user.value.id) {
      (async () => {
        const response = await getData({ source: `/users/${user.value.id}` });
        if (response.status === 200) {
          uuid.setValue(response.data.uuid);
          name.setValue(response.data.name);
          email.setValue(response.data.email);
          phone.setValue(response.data.phone);
          setFoto(`${BASE_URL}/profiles/${response.data.photo}`);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, user.value]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!uuid.value || !name.value || !email.value || !phone.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    const formData = new FormData(event.target);
    setError(undefined);
    let response = await getData({ source: `/users/${user.value.id}`, body: formData, method: 'PUT' });
    if (response.status === 200) {
      await swal('Perfil actualizado con exito.', { icon: 'success' });
    } else {
      setError(response.message);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <MyPaper title='Foto de perfil'>
            <Grid container justify='center' alignItems='center'>
              <StyledAvatar src={foto || '/assets/images/profile.png'} />
            </Grid>
            <Grid container justify='center' alignItems='center'>
              <File type='file' id='foto' name='photo' onChange={handleFileChange} />
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  inputFile.click();
                }}
              >
                Seleccionar
              </Button>
            </Grid>
          </MyPaper>
        </Grid>
        <Grid item xs={12} md={8}>
          <MyPaper title={title}>
            <Grid container item spacing={2}>
              <MyInput name='uuid' label='RUC/DNI' toCase='upper' autoFocus={true} controller={uuid} disabled={loading} />

              <MyInput name='name' label='Nombre' toCase='upper' required={true} controller={name} disabled={loading} />

              <MyInput name='email' label='Correo' type='email' toCase='lower' required={true} controller={email} disabled={loading} />

              <MyInput
                name='password'
                label='Contraseña'
                type='password'
                required={id ? false : true}
                controller={password}
                disabled={loading}
              />

              <MyInput name='phone' label='Telefono' type='number' controller={phone} disabled={loading} />

              <Grid container item justify='flex-end' alignItems='center' spacing={2}>
                <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
                  {loading && (
                    <Fragment>
                      <CircularProgress />
                      <Box ml={2}>
                        <Typography variant='h6' color='textSecondary'>
                          Procesando...
                        </Typography>
                      </Box>
                    </Fragment>
                  )}
                  {error && (
                    <Typography variant='h6' color='error'>
                      {error}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MyPaper>
        </Grid>
      </Grid>
    </form>
  );
};
