import styled from 'styled-components';
export const StyledTitle = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  height: 0;
  margin: 1.5rem 0;
  && h2 {
    position: absolute;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: -1rem;
    margin-left: 20px;
    padding: 0 10px;
    background: white;
  }
`;
