import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const UserMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'uuid',
      label: 'RUC/DNI',
      options: {
        filter: true,
      },
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
      },
    },
    {
      name: 'email',
      label: 'EMAIL',
      options: {
        filter: true,
      },
    },
    {
      name: 'phone',
      label: 'Telefono',
      options: {
        filter: true,
      },
    },
    {
      name: 'role',
      label: 'Rol',
      options: {
        filter: true,
      },
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Usuarios' source='/users' customColumns={customColumns} />;
};
