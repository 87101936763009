import React, { useState, useEffect, useCallback, Fragment } from 'react'
import {
    Grid,
    Box,
    Button,
    CircularProgress,
    Typography,
} from '@material-ui/core'

import { MyPaper } from '../../../components/MyPaper'
import { MyInput } from '../../../components/MyInput'
import { MySelect } from '../../../components/MySelect'

import { useInputValue } from '../../../hooks/useInputValue'
import { useFetch } from '../../../hooks/useFetch'
import { useDialog } from '../../../hooks/useDialog'
import { MyDivider } from '../../../components/MyDivider'

export const UserRegister = ({ id }) => {

    const [title, setTitle] = useState('Registrar')
    const [error, setError] = useState(undefined)
    const {loading, getData} = useFetch()
    const {success} = useDialog()

    // Controladores para los campos de formulario
    const uuid = useInputValue('')
    const name = useInputValue('')
    const email = useInputValue('')
    const password = useInputValue('')
    const phone = useInputValue('')
    const role = useInputValue('')
    const status = useInputValue(1)
    
    // Data para los selects
    const [roleOption, setRoleOption] = React.useState([])

    // Funciones y Callbacks
    const setDataF = (data) => {
        uuid.setValue(data.uuid)
        name.setValue(data.name)
        email.setValue(data.email)
        phone.setValue(data.phone)
        role.setValue(data.role)
        status.setValue(data.status)
    }, setData = useCallback(setDataF, [])

    // Inicializando
    useEffect(() => {
        id && setTitle('Editar');

        (async function() {
            const [roles, user] = await Promise.all([
                getData({ source: '/roles/', bg: true }),
                id && getData({ source: `/users/${id}` })
            ])
            
            roles.status === 200 && setRoleOption(roles.data)
            id && (user.status === 200 && setData(user.data))
        })()
    }, [id, getData, setData])

    // Manejador de envio del formulario
    const handleSubmit = async (event) => {
        event.preventDefault()
        let validation = 0

        if(!name.value) {
            name.setError('Campo requerido')
            validation++
        }
        if(!email.value){
            email.setError('Campo requerido')
            validation++
        }
        if(!id) {
            if(!password.value){
                password.setError('Campo requerido')
                validation++
            }
        }
        if(!role.value){
            role.setError('Campo requerido')
            validation++
        }

        if(!status.value){
            status.setError('Campo requerido')
            validation++
        }

        if(!validation) {
            const formData = new FormData(event.target)
            setError(undefined)
            let message = undefined
            let response = null
            if(id) {
                message = 'Usuario actualizado con exito.'
                response = await getData({ source: `/users/${id}`, body: formData, method: 'PUT' })
            } else {
                message = 'Usuario registrado con exito.'
                response = await getData({ source: '/users/store', body: formData, method: 'POST' })
            }
            if(response.status === 200) {
                success(message).then()
                .then(() => window.history.back())
            } else {
                setError(response.message)
            }
        }

    }

    return (

        <MyPaper title={title}>

            <form
                noValidate
                onSubmit={handleSubmit}
            >

                <Grid container item spacing={2}>

                    <MyInput
                        name="uuid"
                        label="RUC/DNI"
                        toCase="upper"
                        autoFocus={true}
                        controller={uuid}
                        disabled={loading}
                    />

                    <MyInput
                        name="name"
                        label="Nombre"
                        toCase="upper"
                        required={true}
                        controller={name}
                        disabled={loading}
                    />

                    <MyInput
                        name="email"
                        label="Correo"
                        type="email"
                        toCase="lower"
                        required={true}
                        controller={email}
                        disabled={loading}
                    />

                    <MyInput
                        name="password"
                        label="Contraseña"
                        type="password"
                        required={id ? false : true}
                        controller={password}
                        disabled={loading}
                    />

                    <MyInput
                        name="phone"
                        label="Telefono"
                        type="number"
                        controller={phone}
                        disabled={loading}
                    />

                    <MySelect
                        name="role"
                        label="Rol"
                        required={true}
                        controller={role}
                        disabled={loading}
                        data={roleOption}
                    />

                    <MySelect
                        name="status"
                        label="Estado"
                        required={true}
                        controller={status}
                        disabled={loading}
                        data={[
                            {id: 0, name: 'INHABILITADO'},
                            {id: 1, name: 'HABILITADO'},
                        ]}
                    />

                    <Grid item xs={12}>
                        <MyDivider />
                    </Grid>

                    <Grid container item justify="flex-end" alignItems="center" spacing={2}>

                        <Grid container item justify="flex-start" alignItems="center" xs={12} md={6}>
                            {loading &&
                                <Fragment>
                                    <CircularProgress />
                                    <Box ml={2}>
                                        <Typography variant="h6" color="textSecondary">Procesando...</Typography>
                                    </Box>
                                </Fragment>
                            }
                            {error && <Typography variant="h6" color="error">{error}</Typography>}
                        </Grid>

                        <Grid item xs={6} md={3}>
                            
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                onClick={() => window.history.back()}
                            >
                                Atras
                            </Button>

                        </Grid>

                        <Grid item xs={6} md={3}>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                            >
                                Guardar
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>

            </form>

        </MyPaper>

    )

}