import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const BillingMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero_orden_produccion',
      label: 'Orden de Produccion',
      options: {
        filter: true,
      },
    },
    {
      name: 'codigo',
      label: 'Codigo de Cliente',
      options: {
        filter: true,
      },
    },
    {
      name: 'razon_social',
      label: 'Cliente',
      options: {
        filter: true,
      },
    },
    {
      name: 'descripcion',
      label: 'Descripcion del Producto',
      options: {
        filter: true,
      },
    },
    {
      name: 'numero_factura',
      label: '#Factura',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha',
      label: 'Fecha de la Factura',
      options: {
        filter: true,
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Facturacion' source='/billing' customColumns={customColumns} canRegister={false} canDelete={false} />;
};
