import React from 'react';
import { MyDataTable } from '../../../components/MyDataTable';

export const ProductMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'codigo_cliente',
      label: 'Codigo de Cliente',
      options: {
        filter: true,
      },
    },
    {
      name: 'codigo_producto',
      label: 'Codigo de Producto',
      options: {
        filter: true,
      },
    },
    {
      name: 'descripcion',
      label: 'Descripcion',
      options: {
        filter: true,
      },
    },
  ];
  return <MyDataTable title='Productos' source='/products' customColumns={customColumns} />;
};
